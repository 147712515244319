import {Container, Row, Col, Form} from "react-bootstrap";
import ContactMap from "../components/map/ContactMap";
import React, {useState, useEffect, SyntheticEvent} from "react";
import {IMAGES} from "../constants/images";
import api from "../api";
import {useDispatch} from "react-redux";
import {toggleModalThanks} from "../redux/actions/modals";
import {Helmet} from "react-helmet";
import FormCommunicationManager from "../components/forms/form-communication-manager/FormCommunicationManager";
import PageTransition from "../components/PageTransition";

interface Errors {
  name?: string;
  email?: string;
  comment?: string;
  isAgree?: string;
}

const ContactsPage = (props) => {
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [errors, setErrors] = useState<Errors>({});

  const dispatch = useDispatch();

  useEffect(() => {
    setErrors({...errors, name: ""});
  }, [name]);

  useEffect(() => {
    setErrors({...errors, email: ""});
  }, [email]);

  useEffect(() => {
    setErrors({...errors, comment: ""});
  }, [comment]);

  useEffect(() => {
    setErrors({...errors, isAgree: ""});
  }, [isAgree]);

  const submitForm = async (e: SyntheticEvent) => {
    e.preventDefault();

    const newErrors = {};

    if (!name) newErrors["name"] = "Введите имя";
    if (!email) newErrors["email"] = "Введите e-mail";
    if (!comment) newErrors["comment"] = "Введите комментарий";
    if (!isAgree) newErrors["isAgree"] = "Дайте согласие на обработку данных";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    await api.postFeedback({
      name,
      email,
      comment
    });

    setComment("");
    setEmail("");
    setName("");

    dispatch(toggleModalThanks(true));
  };

  return (
    <PageTransition>
      <section className="contacts-page">
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{props.title}</title>
        </Helmet>
        <Container fluid className="contacts-banner">
          <img
            src={IMAGES.ContactsBg}
            className="contacts-banner__image"
            alt="Контакты"
          />

          <Container className="cart-banner__container">
            <div className="cart-banner__title-block title-bg">
              <h1 className="about-company-page-banner__title cart-banner__title">
                Контакты
              </h1>
            </div>
          </Container>
        </Container>

        <Container fluid className="contacts-delivery">
          <Container>
            <Row>
              <Col xl={12}>
                <p>
                  <img src={IMAGES.DoubleMoon} alt="Логотип"/>
                  Доставка вашего товара транспортными компаниями осуществляется
                  по территории России и в страны СНГ. Рассчет стоимости доставки
                  осуществляется у представителей транспортной компании или вашего
                  персонального менеджера.
                </p>
              </Col>
            </Row>
          </Container>
        </Container>

        <ContactMap/>

        <FormCommunicationManager/>

        {/*<Container fluid className="contacts-form-container">*/}
        {/*  <Container >*/}
        {/*    <Row>*/}
        {/*      <Col xl={12}>*/}
        {/*        <div className="contacts-form-container__title">*/}
        {/*          Свяжитесь с нами. Менеджер ответит на ваши вопросы через*/}
        {/*          несколько минут.*/}
        {/*        </div>*/}

        {/*        <Form className="contacts-form" onSubmit={(e) => submitForm(e)}>*/}
        {/*          <div className="contacts-form__column">*/}
        {/*            <Form.Group className="contacts-form__form-group">*/}
        {/*              <Form.Control*/}
        {/*                className="contacts-form__form-control"*/}
        {/*                placeholder="ФИО *"*/}
        {/*                name="name"*/}
        {/*                value={name}*/}
        {/*                onChange={(e) => setName(e.target.value)}*/}
        {/*              />*/}

        {/*              {errors.name && (*/}
        {/*                <div className="modal-order-call__form-error">*/}
        {/*                  <img src={IMAGES.ErrorRed} alt="!" /> {errors.name}*/}
        {/*                </div>*/}
        {/*              )}*/}
        {/*            </Form.Group>*/}

        {/*            <Form.Group className="contacts-form__form-group">*/}
        {/*              <Form.Control*/}
        {/*                className="contacts-form__form-control"*/}
        {/*                placeholder="Email *"*/}
        {/*                name="email"*/}
        {/*                value={email}*/}
        {/*                onChange={(e) => setEmail(e.target.value)}*/}
        {/*              />*/}

        {/*              {errors.email && (*/}
        {/*                <div className="modal-order-call__form-error">*/}
        {/*                  <img src={IMAGES.ErrorRed} alt="!" /> {errors.email}*/}
        {/*                </div>*/}
        {/*              )}*/}
        {/*            </Form.Group>*/}

        {/*            <Form.Group>*/}
        {/*              <button*/}
        {/*                type="submit"*/}
        {/*                className="button button--blue contacts-form__button-blue"*/}
        {/*              >*/}
        {/*                Отправить сообщение*/}
        {/*              </button>*/}
        {/*            </Form.Group>*/}
        {/*          </div>*/}

        {/*          <div className="contacts-form__column">*/}
        {/*            <Form.Group className="contacts-form__form-group">*/}
        {/*              <Form.Control*/}
        {/*                className="contacts-form__form-control"*/}
        {/*                as="textarea"*/}
        {/*                placeholder="Комментарий *"*/}
        {/*                name="comment"*/}
        {/*                value={comment}*/}
        {/*                onChange={(e) => setComment(e.target.value)}*/}
        {/*              />*/}

        {/*              {errors.comment && (*/}
        {/*                <div className="modal-order-call__form-error">*/}
        {/*                  <img src={IMAGES.ErrorRed} alt="!" /> {errors.comment}*/}
        {/*                </div>*/}
        {/*              )}*/}
        {/*            </Form.Group>*/}

        {/*            <Form.Group className="contacts-form__form-group contacts-form__form-group-ext">*/}
        {/*              <div className="contacts-form__ext">*/}
        {/*                * Поля, обязательные для заполнения*/}
        {/*              </div>*/}

        {/*              <div className="contacts-form__agreement">*/}
        {/*                <label htmlFor="agree">*/}
        {/*                  Я согласен с <a href="#">условиями передачи данных</a>*/}
        {/*                </label>*/}

        {/*                <input*/}
        {/*                  id="agree"*/}
        {/*                  type="checkbox"*/}
        {/*                  name="agreement"*/}
        {/*                  checked={isAgree}*/}
        {/*                  onChange={() => setIsAgree(!isAgree)}*/}
        {/*                />*/}

        {/*                {errors.isAgree && (*/}
        {/*                  <div className="modal-order-call__form-error">*/}
        {/*                    <img src={IMAGES.ErrorRed} alt="!" /> {errors.isAgree}*/}
        {/*                  </div>*/}
        {/*                )}*/}
        {/*              </div>*/}
        {/*            </Form.Group>*/}
        {/*          </div>*/}
        {/*        </Form>*/}

        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </Container>*/}
        {/*</Container>*/}
      </section>
    </PageTransition>
  );
};

export default ContactsPage;
