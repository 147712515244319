import React, {useState, SyntheticEvent, useRef, useEffect} from "react";

import {IMAGES} from "../../constants/images";
import {Form} from "react-bootstrap";
import InputMask from "react-input-mask";
import api from "../../api";
import {useDispatch, useSelector} from "react-redux";
import {toggleModalThanks, toggleModalCall, toggleModalCallMobile} from "../../redux/actions/modals";


const OrderCall = ({type}) => {
  // interface Errors {
  //   name?: string;
  //   phone?: string;
  //   isAgree?: string;
  // }

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [isAgree, setIsAgree] = useState(false);
  const [errors, setErrors] = useState({});

  const modals = useSelector((state) => state.modals);

  const dispatch = useDispatch();

  const handleClose = () => {
    setErrors({});
    setName("");
    setPhone("");
    setIsAgree(false);

    dispatch(toggleModalCall(false));
    dispatch(toggleModalCallMobile(false));
  };

  useEffect(() => {
    setErrors({...errors, name: ""});
  }, [name]);

  useEffect(() => {
    setErrors({...errors, phone: ""});
  }, [phone]);

  useEffect(() => {
    setErrors({...errors, isAgree: ""});
  }, [isAgree]);

  const submitForm = async (e) => {
    e.preventDefault();
    e.stopPropagation()
    const newErrors = {};

    if (!name) newErrors["name"] = "Введите имя";
    if (!phone) newErrors["phone"] = "Введите телефон";
    if (!isAgree) newErrors["isAgree"] = "Дайте согласие на обработку данных";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    await api.postCall({
      name,
      phone,
    });

    handleClose();

    dispatch(toggleModalThanks(true));
  };

  return (
   <>
      <div className="modal-order-call__header">
        <div className="modal-order-call__title">Заказать звонок</div>
        <button
          type="button"
          className="modal-order-call__close"
          onClick={() => handleClose()}
        >
          <img src={IMAGES.CloseBlackIcon} alt=""/>
        </button>
      </div>

      <div className="modal-order-call__p">
        Оставьте контактные данные и менеджер перезвонит вам в ближайшее
        время
      </div>

      <Form className="modal-order-call__form" onSubmit={submitForm}>
        <Form.Group className="modal-order-call__form-group">
          <Form.Control
            className={`modal-order-call__form-control${
              errors.name ? " modal-order-call__form-control--error" : ""
            }`}
            name="name"
            placeholder="Ваше имя"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          {errors.name && (
            <div className="modal-order-call__form-error">
              <img src={IMAGES.ErrorRed} alt="!"/> {errors.name}
            </div>
          )}
        </Form.Group>

        <Form.Group className="modal-order-call__form-group">
          <InputMask
            className={`form-control modal-order-call__form-control${
              errors.phone ? " modal-order-call__form-control--error" : ""
            }`}
            mask="+79999999999"
            name="phone"
            placeholder="+7"
            maskPlaceholder=""
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          {errors.phone && (
            <div className="modal-order-call__form-error">
              <img src={IMAGES.ErrorRed} alt="!"/> {errors.phone}
            </div>
          )}
        </Form.Group>

        <Form.Group className="modal-order-call__form-group">
          <div className="modal-order-call__agreement">
            <label htmlFor="agree" className="agreement form-check d-inline-block">
              Я согласен с <a href="/politika-konfidentsialnosti">условиями</a> передачи данных
              <input
                id="agree"
                type="checkbox"
                checked={isAgree}
                name="agreement"
                onChange={() => setIsAgree(!isAgree)}
              />
              <div className="checkbox"/>
            </label>


          </div>

          {errors.isAgree && (
            <div className="modal-order-call__form-error">
              <img src={IMAGES.ErrorRed} alt="!"/> {errors.isAgree}
            </div>
          )}
        </Form.Group>

        <div className="modal-order-call__form-bottom">
          <button className="button button--red modal-order-call__button-red">
            Отправить
          </button>
        </div>
      </Form>
    </>
  );
};

export default OrderCall;
