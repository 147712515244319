import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import MainLayout from "../layouts/MainLayout";

export default function ScrollToTop(props) {

  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
    window.scrollTo(0, 0)
  }, [location.pathname]);

  return <>
    {props.children}
  </>
}

