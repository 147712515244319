import axios from 'axios';

export default {
  getFilter: async (url:string) => axios.get(`api/filter?${url}`).then(res => res.data),
  getFilterLinkSections: async () => axios.get(`api/filter`).then(res => res.data),
  getFilterDefaultPricesRange: async () => axios.get(`api/filter`).then(res => res.data),
  getModels: async (url) => axios.get(`api/models${url}`).then(res => res.data),
  getModelsBySection: async (id, url) => axios.get(`api/models_by_sections/${id}${url}`).then(res => res.data),
  getModel: async (id) => axios.get(`api/models/${id}`).then(res => res.data),
  getBrands: async () => axios.get(`api/brands`).then(res => res.data),
  getProduct: async (id) => axios.get(`api/product/${id}`).then(res => res.data),
  getProductByModel: async (model_id) => axios.get(`api/product/get_products_by_model/${model_id}`).then(res => res.data),
  getSection: async () => axios.get(`api/sections`).then(res => res.data),
  setOrder: async (data) => axios.post(`api/order`, data).then(res => res.data),
  getProducts: async (url:string) => axios.get(`api/filter/get_products?${url}`).then(res => res.data),
  postCall: async (data) => axios.post("api/callback", data).then(res => res.data),
  postFeedback: async (data) => axios.post("api/feedback", data).then(res => res.data),
  getPopularProducts: async () => axios.get("api/popular_products").then(res => res.data),
  getPopularPModels: async () => axios.get("api/popular_models").then(res => res.data),
  postDealerFeedback: async (data) => axios.post("api/dealer_feedback", data).then(res => res.data),
}

