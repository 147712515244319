import Logo from "../assets/images/Logo.svg";
import CartIcon from "../assets/icons/cart.svg";
import CloseIcon from "../assets/icons/close.svg";
import CloseBlackIcon from "../assets/icons/close-black.svg";
import CloseWhiteIcon from "../assets/icons/close-white.svg";
import Tyre from "../assets/images/tyre.png";
import BannerSlide1 from "../assets/images/banner-slide-1.jpg";
import BannerSlide2 from "../assets/images/banner-slide-2.jpg";
import BannerSlide3 from "../assets/images/banner-slide-3.jpg";
import ErrorRed from "../assets/icons/error-red.svg";
import Model1 from "../assets/images/model-1.svg";
import Model2 from "../assets/images/model-2.svg";
import Model3 from "../assets/images/model-3.svg";
import About1 from "../assets/images/about-1.jpg";
import About2 from "../assets/images/about-2.jpg";
import About3 from "../assets/images/about-3.jpg";
import About4 from "../assets/images/about-4.jpg";
import About5 from "../assets/images/about-5.jpg";
import DeliveryInfo1 from "../assets/images/delivery-info-1.png";
import DeliveryInfo2 from "../assets/images/delivery-info-2.png";
import DeliveryInfo3 from "../assets/images/delivery-info-3.png";
import DeliveryInfo4 from "../assets/images/delivery-info-4.png";
import Delivery1 from "../assets/images/delivery-1.svg";
import Delivery2 from "../assets/images/delivery-2.svg";
import Delivery3 from "../assets/images/delivery-3.svg";
import Delivery4 from "../assets/images/delivery-4.svg";
import Delivery5 from "../assets/images/delivery-5.svg";
import ArrowDashed from "../assets/images/arrow-dashed.svg";
import Ru from "../assets/icons/ru.svg";
import T from "../assets/icons/t.svg";
import Point from "../assets/icons/point.svg";
import Mail from "../assets/icons/mail.svg";
import Phone from "../assets/icons/phone.svg";
import ErrorPageBg from "../assets/images/error-bg.jpg";
import Service1 from "../assets/images/service-1.svg";
import Service2 from "../assets/images/service-2.svg";
import Service3 from "../assets/images/service-3.svg";
import AboutCompanyBg from "../assets/images/about-company-bg.jpg";
import TyreMenu1 from "../assets/images/tyre-menu-1.png";
import TyreMenu2 from "../assets/images/tyre-menu-2.png";
import TyreMenu3 from "../assets/images/tyre-menu-3.png";
import TyreMenu4 from "../assets/images/tyre-menu-4.png";
import TyreMenu5 from "../assets/images/tyre-menu-5.png";
import Car1 from "../assets/icons/car-1.svg";
import Car2 from "../assets/icons/car-2.svg";
import Car3 from "../assets/icons/car-3.svg";
import Car4 from "../assets/icons/car-4.svg";
import Car5 from "../assets/icons/car-5.svg";
import ProductBanner1 from "../assets/images/product-banner-1.png";
import ProductBanner2 from "../assets/images/product-banner-2.png";
import ProductBanner3 from "../assets/images/product-banner-3.png";
import Advantages1 from "../assets/images/advantages-1.jpg";
import Advantages2 from "../assets/images/advantages-2.jpg";
import Advantages3 from "../assets/images/advantages-3.jpg";
import Advantages4 from "../assets/images/advantages-4.jpg";
import Sert1 from "../assets/images/sert-1.jpg";
import Sert2 from "../assets/images/sert-2.jpg";
import Sert3 from "../assets/images/sert-4.jpg";
import Sert4 from "../assets/images/sert-5.jpg";
import Sert5 from "../assets/images/sert-6.jpg";
import Sert6 from "../assets/images/sert-3.jpg";
import Sert7 from "../assets/images/sert-7.jpg";
import SertBig7 from "../assets/images/sert-big-7.jpg";
import CartBanner1 from "../assets/images/cart-bg.jpg";
import DeliveryOrg1 from "../assets/images/delivery-org-1.png";
import DeliveryOrg2 from "../assets/images/delivery-org-2.png";
import DeliveryOrg3 from "../assets/images/delivery-org-3.png";
import DeliveryOrg4 from "../assets/images/delivery-org-4.png";
import DeliveryOrg5 from "../assets/images/delivery-org-5.png";
import ProductPageBg1 from "../assets/images/product-page-bg-1.png";
import DoubleMoon from "../assets/images/double-moon.png";
import Zoom from "../assets/icons/zoom.svg";
import TyreProduct from "../assets/images/tyreProduct.png";
import ContactsBg from "../assets/images/contacts-bg.jpg";
import CatalogBg1 from "../assets/images/CatalogBg1.jpg";
import CatalogBgTruck from "../assets/images/CatalogBgTruck.jpg";
import CatalogBgAll from "../assets/images/CatalogBgAll.jpg";
import TechnologiesBg from "../assets/images/technologies.png";
import Technology1 from "../assets/images/technology-1.jpg";
import Technology2 from "../assets/images/technology-2.jpg";
import Technology3 from "../assets/images/technology-3.jpg";
import Technology4 from "../assets/images/technology-4.jpg";
import Technology5 from "../assets/images/technology-5.jpg";
import Technology6 from "../assets/images/technology-6.jpg";
import Technology7 from "../assets/images/technology-7.jpg";
import Technology8 from "../assets/images/technology-8.jpg";
import Technology9 from "../assets/images/technology-9.jpg";
import Technology10 from "../assets/images/technology-10.jpg";
import DeliveryBg from "../assets/images/delivery-bg.jpg";
import InviteBg from "../assets/images/invite-bg.jpg";
import InviteBg1 from "../assets/images/invite-bg1.jpg";
import DeliveryPaymentBg1 from "../assets/images/delivery-payment-bg-1.jpg";
import DeliveryPaymentBg2 from "../assets/images/delivery-payment-bg-2.jpg";
import DeliveryPaymentLayout1 from "../assets/images/delivery-payment-layout-1.jpg";
import DeliveryPaymentLayout2 from "../assets/images/delivery-payment-layout-2.jpg";
import DeliveryPaymentLayout3 from "../assets/images/delivery-payment-layout-3.jpg";
import PaymentBg1 from "../assets/images/payment-bg-1.jpg";
import PaymentBg2 from "../assets/images/payment-bg-2.jpg";
import PaymentBg3 from "../assets/images/payment-bg-3.jpg";
import PaymentLayout1 from "../assets/images/payment-layout-1.jpg";
import PaymentLayout2 from "../assets/images/payment-layout-2.jpg";
import PaymentLayout3 from "../assets/images/payment-layout-3.jpg";
import RewindBg1 from "../assets/images/rewind-bg-1.jpg";
import RewindBg2 from "../assets/images/rewind-bg-2.jpg";
import RewindLayout1 from "../assets/images/rewind-layout-1.jpg";
import RewindLayout2 from "../assets/images/rewind-layout-2.jpg";
import CatalogBg from "../assets/images/CatalogBg.jpg";
import FilterBorder from "../assets/icons/filter-border.svg";
import FilterIcon from "../assets/icons/dropdawn-filter-icon.svg";
import SearchIcon from "../assets/icons/search-filter.svg";
import MenuCheckIcon from "../assets/icons/menu-check.svg";
import CartStub from "../assets/images/cart-stub-image.png";
import Icon1 from "../assets/icons/icon-1.svg";
import Icon2 from "../assets/icons/icon-2.svg";
import Image1 from "../assets/images/image-1.svg";
import MobileHeaderLogo from "../assets/images/mobile-header-logo.svg";
import MobileCall from "../assets/icons/mobile-call.svg";
import Filter from "../assets/icons/filter.svg";
import FilterCloseBubble from "../assets/icons/filter-close-bubble.svg";
import Icon3 from "../assets/icons/icon-3.svg";
import Icon4 from "../assets/icons/icon-4.svg";
import Icon5 from "../assets/icons/icon-5.svg";
import Icon6 from "../assets/icons/icon-6.svg";
import Icon7 from "../assets/icons/icon-7.svg";
import Icon8 from "../assets/icons/icon-8.svg";
import Icon9 from "../assets/icons/icon-9.svg";
import Icon10 from "../assets/icons/icon-10.svg";
import Icon11 from "../assets/icons/icon-11.svg";
import Geo from "../assets/images/geo.jpg";

import ModelsPage1 from "../assets/images/Model1.jpg";
import ModelsPage2 from "../assets/images/Model2.jpg";
import ModelsPage3 from "../assets/images/Model3.jpg";
import Invite from "../assets/images/invite.svg";
import Geo1 from "../assets/images/geo1.jpg";
import Geo2 from "../assets/images/geo2.jpg";
import Geo3 from "../assets/images/geo3.jpg";
import Geo4 from "../assets/images/geo4.jpg";
import Geo5 from "../assets/images/geo5.jpg";
import InviteDiler from "../assets/images/invite-diler.png";


export const IMAGES = {
  Logo,
  CartIcon,
  CloseIcon,
  CloseBlackIcon,
  ErrorRed,
  Tyre,
  BannerSlide1,
  BannerSlide2,
  BannerSlide3,
  Model1,
  Model2,
  Model3,
  About1,
  About2,
  About3,
  About4,
  About5,
  DeliveryInfo1,
  DeliveryInfo2,
  DeliveryInfo3,
  DeliveryInfo4,
  Delivery1,
  Delivery2,
  Delivery3,
  Delivery4,
  Delivery5,
  ArrowDashed,
  Ru,
  T,
  Point,
  Mail,
  Phone,
  ErrorPageBg,
  Service1,
  Service2,
  Service3,
  AboutCompanyBg,
  TyreMenu1,
  TyreMenu2,
  TyreMenu3,
  TyreMenu4,
  TyreMenu5,
  Car1,
  Car2,
  Car3,
  Car4,
  Car5,
  ProductBanner1,
  ProductBanner2,
  ProductBanner3,
  Advantages1,
  Advantages2,
  Advantages3,
  Advantages4,
  Sert1,
  Sert2,
  Sert3,
  Sert4,
  Sert5,
  Sert6,
  DeliveryBg,
  DeliveryPaymentBg1,
  DeliveryPaymentBg2,
  CartBanner1,
  DeliveryOrg1,
  DeliveryOrg2,
  DeliveryOrg3,
  DeliveryOrg4,
  DeliveryOrg5,
  ProductPageBg1,
  DoubleMoon,
  Zoom,
  TyreProduct,
  DeliveryPaymentLayout1,
  DeliveryPaymentLayout2,
  DeliveryPaymentLayout3,
  PaymentBg1,
  PaymentBg2,
  PaymentLayout1,
  PaymentLayout2,
  ContactsBg,
  TechnologiesBg,
  Technology1,
  Technology2,
  Technology3,
  Technology4,
  Technology5,
  Technology6,
  Technology7,
  Technology8,
  Technology9,
  Technology10,
  CatalogBg,
  FilterBorder,
  FilterIcon,
  SearchIcon,
  MenuCheckIcon,
  CloseWhiteIcon,
  RewindBg1,
  RewindBg2,
  RewindLayout1,
  RewindLayout2,
  CatalogBgTruck,
  CatalogBgAll,
  CartStub,
  Icon1,
  Icon2,
  Image1,
  CatalogBg1,
  ModelsPage1,
  ModelsPage2,
  ModelsPage3,
  MobileHeaderLogo,
  MobileCall,
  Filter,
  FilterCloseBubble,
  Icon3,
  Invite,
  PaymentLayout3,
  Icon4,
  Icon5,
  Icon6,
  PaymentBg3,
  InviteBg,
  InviteBg1,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  Icon11,
  Sert7,
  SertBig7,
  Geo,
  Geo1,
  Geo2,
  Geo3,
  Geo4,
  Geo5,
  InviteDiler
};
