import {
  SET_PRODUCT_CART, GET_PRODUCT_CART,
  OPEN_MODAL_CART, RESET_MODAL_CART,
  REMOVE_PRODUCT_UNIT_CART, SELECT_ALL_PRODUCT,
  REMOVE_ALL_PRODUCT_CART, DELETE_PRODUCT, TOGGLE_DROP_WINDOW_CART,
  SELECT_PRODUCT_UNIT_CART,
  RESET_CART,
  UNSELECT_PRODUCT_CART,
  SET_DELETE_PRODUCT
} from "../constants";

export const setProductCart =(data)=>({type: SET_PRODUCT_CART, payload: data})
export const removeProductUnitCart =(data)=>({type: REMOVE_PRODUCT_UNIT_CART, payload: data})
export const getProductCart =()=>({type: GET_PRODUCT_CART})
export const openCart =()=>({type: OPEN_MODAL_CART})
export const resetModalCart =()=>({type: RESET_MODAL_CART})
export const selectAllProduct =(data)=>({type: SELECT_ALL_PRODUCT, payload: data})
export const removeAllProductCart =()=>({type: REMOVE_ALL_PRODUCT_CART})
export const removeProductCart =(data)=>({type: DELETE_PRODUCT, payload: data})
export const toggleDropWindowCart =(boolean)=>({type: TOGGLE_DROP_WINDOW_CART, payload: boolean})
export const selectProductUnitCart =(data)=>({type: SELECT_PRODUCT_UNIT_CART, payload: data})
export const resetCart =()=>({type: RESET_CART})
export const unselectProductCart =()=>({type: UNSELECT_PRODUCT_CART})
export const setDeleteProduct =(data)=>({type: SET_DELETE_PRODUCT, payload: data})


