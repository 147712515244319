import React, {useState} from "react";
import InputMask from "react-input-mask";
import api from "../../../api/index";
import {Col, Container, Form} from "react-bootstrap";
import {IMAGES} from "../../../constants/images";
import {toggleModalThanks} from "../../../redux/actions/modals";
import {useDispatch} from "react-redux";
import {setErrors} from "../../../redux/actions/making_order";

const FormInvite = () => {

  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [title, setTitle] = useState(null);
  const [address, setAddress] = useState(null);
  const [inn, setInn] = useState(null);
  const [agreement, setAgreement] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const reset = () => {
    setName(null);
    setPhone(null);
    setEmail(null);
    setTitle(null);
    setAddress(null);
    setInn(null);
    setAgreement(null);
    setSubmiting(null);
    setError(null);
  };

  const submit = (e) => {
    setError(null)
    e.preventDefault();
    if (!agreement) {
      setError("Согласитесь с политикой конфиденциальности");
      return;
    }
    setSubmiting(true);
    let data = {name, phone, email, title, address, inn};
    api.postDealerFeedback(data).then(res => {
      reset();
      dispatch(toggleModalThanks(true));
    }).catch(e => {
      setError("что-то пошло не так");
    }).finally(() => {
      setSubmiting(false);
    });
  };

  return (
    <Container>
      <form className="form-invite-area" onSubmit={submit}>
        <div className="row">
          <div className="col-lg-6 col-12 order-lg-1 order-2">
            <div className="form-order form-invite">

              <span className="form-invite__title">Форма обратной связи</span>

              <div className="form-invite__fields">
                <div className="form-order__form">
                  <label htmlFor="fio" className="form-label">
                    фио*
                  </label>
                  <input
                    className="form-control form-order__form-control"
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name ? name : ""}
                    type="text"/>
                </div>
                <div className="form-order__form">
                  <label htmlFor="fio" className="form-label">
                    номер телефона*
                  </label>
                  <InputMask
                    required
                    mask="+7 (999) 999 99 99"
                    // name="phone"
                    placeholder="+7"
                    maskPlaceholder=""
                    className="form-control form-order__form-control"
                    value={phone ? phone : ""}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="form-order__form">
                  <label htmlFor="fio" className="form-label">
                    email
                  </label>
                  <input
                    className="form-control form-order__form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email ? email : ""}
                    type="email"/>
                </div>
                <div className="form-order__form">
                  <label htmlFor="fio" className="form-label">
                    наименование организации*
                  </label>
                  <input
                    className="form-control form-order__form-control"
                    required
                    onChange={(e) => setTitle(e.target.value)}
                    value={title ? title : ""}
                    type="text"/>
                </div>
                <div className="form-order__form">
                  <label htmlFor="fio" className="form-label">
                    юридический адрес
                  </label>
                  <input
                    className="form-control form-order__form-control"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address ? address : ""}
                    type="text"/>
                </div>
                <div className="form-order__form">
                  <label htmlFor="fio" className="form-label">
                    инн*
                  </label>
                  <input
                    className="form-control form-order__form-control"
                    required
                    onChange={(e) => setInn(e.target.value)}
                    value={inn ? inn : ""}
                    type="number"/>
                </div>
              </div>

              <div className="form-check">
                <label
                  onClick={(e) => setAgreement(!agreement)}
                  htmlFor="agreement" className="agreement">

                  <input
                    checked={agreement ? agreement : ""}
                    onChange={() => null}
                    className="form-check-input"
                    type="checkbox"
                  />

                  <div className="checkbox"/>
                  Я ознакомлен и согласен с
                  {" "}
                  <div className="form-check__agreem">
                    <a
                      href="/politika-konfidentsialnosti"
                      target="_blank">
                      политикой конфиденциальности
                    </a>
                  </div>
                </label>
              </div>
              {error && (
                <div className="modal-order-call__form-error">
                  <img src={IMAGES.ErrorRed} alt="!"/> {error}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-12 order-lg-2 order-1">

            <div className="form-invite-info">
              <h3 className="form-invite-info__title">
                мы предлагаем
              </h3>

              <div className="form-invite-info__item">
                <img
                  className="form-invite-info__item-image"
                  src={IMAGES.Icon7}
                  alt=""
                />
                <p className="form-invite-info__item-text">
                  оперативную и бесперебойную поставку
                </p>
              </div>

              <div className="form-invite-info__item">
                <img
                  className="form-invite-info__item-image"
                  src={IMAGES.Icon8}
                  alt=""
                />
                <p className="form-invite-info__item-text">
                  гарантию качества
                </p>
              </div>

              <div className="form-invite-info__item">
                <img
                  className="form-invite-info__item-image"
                  src={IMAGES.Icon9}
                  alt=""
                />
                <p className="form-invite-info__item-text">
                  систему скидок
                </p>
              </div>

              <div className="form-invite-info__item">
                <img
                  className="form-invite-info__item-image"
                  src={IMAGES.Icon10}
                  alt=""
                />
                <p className="form-invite-info__item-text">
                  профессиональные консультации
                  и помощь в выборе
                </p>
              </div>

              <div className="form-invite-info__item">
                <img
                  className="form-invite-info__item-image"
                  src={IMAGES.Icon11}
                  alt=""
                />
                <p className="form-invite-info__item-text">
                  техническую и рекламную поддержку
                </p>
              </div>

            </div>
          </div>

        </div>
        <div className="form-invite-area__button-area">

          {submiting
            ?
            <button type="submit"
                    disabled
                    className="button button--red form-invite-area__button-red">
              <span>Оправка....</span>
            </button>
            :
            <button type="submit"
                    className="button button--red form-invite-area__button-red">
              <span>отправить заявку</span>
            </button>
          }

        </div>
      </form>

    </Container>
  );
};

export default FormInvite;
