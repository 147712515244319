import {useEffect, useState} from "react";
import {Nav} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import SubLinkIcon from "../../assets/icons/menu-sub-link.svg";
import {LINKS} from "../../constants/links";
import api from "../../api";
import {useDispatch} from "react-redux";
import {IMAGES} from "../../constants/images";
import {createUrl} from "../../redux/actions/filters";

const Navigation = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const init = () => {
    api
      .getFilterDefaultPricesRange()
      .then((res) => {
        dispatch({type: "SET_DEFAULT_PRICES", payload: res.prices});
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    init();
    return () => {
      setLoading(true);
    };
  }, []);

  const isActiveTop = (path) => {
    return (location.pathname.trim() + location.search.trim()).includes(
      path.trim()
    );
  };

  const isActiveSub = (path) => {
    // return (location.pathname.trim() + location.search.trim()).endsWith(
    //   path.trim()
    // );
    return (location.pathname.trim()).endsWith(
      path.trim()
    );
  };

  const isActiveParams = (path, section) => {
    let finded = false;
    let hasSections = false;
    const hasPath = (
      location.pathname.trim() + location.search.trim()
    ).includes(path.trim());

    const params = location.search.trim().replace("?", "").split("&");

    params.forEach((param) => {
      const value = param.split("=");

      if (value[0] === "sections") {
        hasSections = true;
        if (+value[1] === section) {
          finded = true;
        }
      }
    });

    if (!finded && !hasSections && hasPath && section === 0) finded = true;

    return finded;
  };

  const onSelectChange = (id) => {
    // console.log('id', id)
    dispatch({type: 'RESET_FILTER_VALUE'})
    const _data = {
      value: +id || +id !== 0 ? +id : null ,
      filter_field: 'sections',
      type: 'select'
    }
    dispatch(createUrl(_data))
  }

  return (
    <Nav
      defaultActiveKey="/"
      as="ul"
      className="header-main__navigation d-lg-flex d-none"
    >
      {LINKS.map((item, index) => {
        return (
          <Nav.Item as="li" key={index} className="header-main__item">
            <Link
              className={`header-main__link${
                isActiveTop(item.link) ? " header-main__link--active" : ""
              }`}
              to={item.link}
            >
              <span>
                {item.name}
                {item.sublink && (
                  <img
                    src={SubLinkIcon}
                    alt=""
                    className="header-main__icon-arrow"
                  />
                )}
              </span>
            </Link>
            {item.sublink && (
              <div className="header-main-sublinks-area">
                <div className="header-main-sublinks">
                  {item?.sublink.map((it, ind) => {
                    return (
                      <Link
                        key={ind}
                        to={it?.link}
                        onClick={()=> it?.matchSection > -1 ? onSelectChange(it?.matchSection?.toString()) : {}}
                        className={
                          it?.matchSection > -1
                            ? `header-main-sublinks__item${
                                isActiveParams(
                                  it?.link,
                                  it?.matchSection
                                )
                                  ? " header-main-sublinks__item--active"
                                  : ""
                              }`
                            : `header-main-sublinks__item${
                                isActiveSub(it?.link)
                                  ? " header-main-sublinks__item--active"
                                  : ""
                              }`
                        }
                      >
                        <span className="header-main-sublinks__text">
                          {it?.matchSection > -1 ? (
                            isActiveParams(
                              it?.link,
                              it?.matchSection
                            ) ? (
                              <img
                                className="header-main-sublinks__icon"
                                src={IMAGES.MenuCheckIcon}
                                alt=""
                              />
                            ) : (
                              ""
                            )
                          ) : isActiveSub(it?.link) ? (
                            <img
                              className="header-main-sublinks__icon"
                              src={IMAGES.MenuCheckIcon}
                              alt=""
                            />
                          ) : (
                            ""
                          )}{" "}
                          {it?.name}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}
          </Nav.Item>
        );
      })}
    </Nav>
  );
};

export default Navigation;
