import React, {useState} from "react";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import {Container, Row, Col} from "react-bootstrap";
import {IMAGES} from "../constants/images";
import AboutGrid from "../components/about-grid/aboutGrid";
import SliderCertificate from "../components/slider-models/SliderCertificate";

import FormCommunicationManager from "../components/forms/form-communication-manager/FormCommunicationManager";
import {Helmet} from "react-helmet";
import PageTransition from "../components/PageTransition";

const AboutCompanyPage = (props) => {
  // console.log(props)

  const list = [
    {
      title: "Шины для любых дорог",
      text: "Шины Starmaxx чаще всего выбирают владельцы крупногабаритных машин и техники, эксплуатируемой в непроходимых условиях бездорожья, работы на лесных и полевых территориях. ",
      image: IMAGES.Advantages1
    },
    {
      title: "высокое качество",
      text: "Благодаря высоким показателям качества покрышки данного производителя в минимальной степени подвержены изломам отдельных частей при воздействии негативных факторов условий работы спецтехники.",
      image: IMAGES.Advantages2
    },
    {
      title: "Экспорт в  другие страны",
      text: "Шины Starmaxx экспортируются в более чем сто стран мира и имеют широкую и стабильную дистрибьюторскую сеть на мировом рынке шин.",
      image: IMAGES.Advantages3
    },
    {
      title: "Уникальный подход",
      text: "Инновации, уникальный подход к качеству и лидерская миссия в сочетании с качественным и ориентированным на клиента производством позволяют бренду Starmaxx постоянно развиваться и укреплять свои позиции на международном рынке.",
      image: IMAGES.Advantages4
    }

  ];

  return (
    <PageTransition>
      <section className="about-company-page">
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{props.title}</title>
        </Helmet>
        {/*<MetaTags>*/}
        {/*  <title>{props.title}</title>*/}
        {/*</MetaTags>*/}
        <Container fluid className="about-company-page-banner">
          <Breadcrumbs/>
          <img src={IMAGES.AboutCompanyBg}
               className="main-banner__image"
               alt="О компании"/>
          <Container className="about-company-page-banner__container">
            <div className="about-company-page-banner__title-block ">
              <h1 className="about-company-page-banner__title">
                о компании
              </h1>
              <p className="about-company-page-banner__sub-title">
                <strong>STARMAXX</strong> является торговой маркой компании <strong>PETLAS</strong> Tire Industry Co.
                Ассортимент шин Starmaxx представлен
                покрышками для различных категорий транспорта и специализированной техники – от легковых автомобилей до
                индустриальных машин.
              </p>
            </div>

          </Container>

        </Container>

        <Container fluid className="about-company-page-info">
          <Container className="about-company-page-info__container">
            <div className="company-product-list">
              <div className="company-product-item">
                <img src={IMAGES.TyreMenu1} alt="" className="company-product-item__image"/>
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car1} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для легковых машин
                </p>
              </div>
              <div className="company-product-item">
                <img src={IMAGES.TyreMenu2} alt="" className="company-product-item__image"/>
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car2} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для грузовиков
                </p>
              </div>
              <div className="company-product-item">
                <img src={IMAGES.TyreMenu3} alt="" className="company-product-item__image"/>
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car3} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для сельхозтехники
                </p>
              </div>
              <div className="company-product-item">
                <img src={IMAGES.TyreMenu4} alt="" className="company-product-item__image"/>
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car4} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для спецтехники
                </p>
              </div>
              <div className="company-product-item">
                <img src={IMAGES.TyreMenu5} alt="" className="company-product-item__image"/>
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car5} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для самолетов
                </p>
              </div>
            </div>
          </Container>
        </Container>

        <Container fluid className="production">
          <Container className="production__container">
            <div className="title-block title-line production__title-block">
              <h2>
                Основная линия производства
              </h2>
            </div>
            <p className="production__text">
              Основной линией производства считаются шины для оснащения вилочных погрузчиков, самосвалов, грузового
              транспорта и спецтехники.
            </p>
          </Container>
        </Container>

        <Container fluid className="production-banners">
          <Container className="production-banners__container">
            <Row>
              <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                <img src={IMAGES.ProductBanner1} alt="" className="production-banners__image"/>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                <img src={IMAGES.ProductBanner2} alt="" className="production-banners__image"/>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                <img src={IMAGES.ProductBanner3} alt="" className="production-banners__image"/>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container fluid className="certificates">
          <Container className="certificates__container">
            <div className="title-block production__title-block">
              <h2>
                СЕРТИФИКАТЫ КАЧЕСТВА
              </h2>
            </div>
            <p className="production__text">
              PETLAS Tire Industry Co. имеет сертификаты обеспечения качества системы ISO 9001 и промышленного
              обеспечения
              качества AQAP-110. Продукция данного бренда проходит тщательный контроль и многочисленные тестирования
              перед
              непосредственной поставкой на рынок.
            </p>
            <SliderCertificate/>
          </Container>
        </Container>


        <Container fluid className="advantages">
          <Container className="advantages__container">
            <div className="title-block title-line production__title-block">
              <h2>
                преимущества шин Starmaxx
              </h2>
            </div>
          </Container>
          <Container className="advantages-list">

            {list.map((item, index) => {
              return (
                <div className="advantages-item" key={index}>
                  <div className="advantages-item__image">
                    <img src={item.image} alt="Шины для любых дорог"/>
                  </div>
                  <div className="advantages-item__info">
                    <div className="button button--skew advantages-item__title">
                      <span>{item.title}</span>
                    </div>
                    <p className="advantages-item__text">
                      {item.text}
                    </p>
                  </div>
                </div>
              );
            })}
          </Container>
        </Container>

        <Container fluid className="companies-num">
          <Container className="companies-num__container">
            <div className="title-block production__title-block">
              <h2>
                о компании в цифрах
              </h2>
            </div>
            <AboutGrid/>
          </Container>
        </Container>

        <FormCommunicationManager/>

      </section>
    </PageTransition>
  );
};

export default AboutCompanyPage;
