import React, {useEffect, useRef, useState} from 'react';
import Select, {components} from "react-select";
import {IMAGES} from "../../constants/images";
import {Filters} from '../../types/types'
import {FiltersFields} from "../../constants/filters";
import useClickOutside from "../../hooks/useClickOutside";
import {useDispatch, useSelector} from "react-redux";
import {createUrl} from "../../redux/actions/filters";
import api from "../../api";
import {RootState} from "../../redux/store";
import {useNavigate} from "react-router-dom";
import {CREATE_URL} from "../../redux/constants";

const FilterSelect = ({filter_field, filter_data}) => {
  // console.log('filter_data', filter_data)
  const customStyles = {

    option: (provided, state) => ({
      // ...provided,
      borderBottom: '1px solid #E5E5E5',
      padding: '5px 0px',
      cursor: 'pointer',
      color: state.isFocused || state.isSelected ? '#E30613' : '#000000',
      font: state.isFocused ? `700 15px/20px 'Open Sans'` : `400 15px/20px 'Open Sans'`,
      backgroundColor: '#fff'
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: '100%',
      display: 'flex',
      height: '40px',
      background: '#FFFFFF',
      padding: "15px 10px 6px 2px",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      borderBottom: "1px solid #E5E5E5"
    }),
  }

  const ref = useRef(null)

  useClickOutside(ref, () => setIsOpen(false))

  const [isOpen, setIsOpen] = useState(false)

  const dispatch = useDispatch()

  // const navigate = useNavigate()

  const filters = useSelector((state) => state.filters);

  const valueSelect=()=>{
    if(filter_field === "sections"){
      const _id = filters?.select_value?.sections[0]
      const res = filters?.select?.sections.filter(item => item.value === +_id)[0]
      return res?.label
    }else{
      return filters?.select_value[filter_field][0]
    }
  }

  let value = valueSelect()

  const defaultValue = 'Выбрать'

  const navigate = useNavigate();

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  // const _url = filters.url

  const onSelectChange = (data) => {
    const _data = {
      value: data.value,
      filter_field: filter_field,
      type: 'select'
    }

    dispatch(createUrl(_data))
    toggleOpen();
    // navigate(`/catalog?${filters.url}`)
    // navigate(`/catalog?${_url}`)
  };

  const handleResetUnitFilter = () => {
    const _data = {
      value: null,
      filter_field: filter_field,
      type: 'select'
    }
    dispatch(createUrl(_data))
    // navigate(`/catalog?${filters.url}`)
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props} className="select-search-icon">
        <img src={IMAGES.SearchIcon} alt=""/>
      </components.DropdownIndicator>
    );
  };

  const hideFilter =()=>{
    if(filters.type === "P" && (filter_field === "widths" || filter_field === "heights" )){
     return 'filter--hide'
    }
    if(filters.type === "S" && (filter_field === "index_size" )){
      return 'filter--hide'
    }
  }
  //
  // console.log(filter_field)

  return (
    <div className={"filter " + hideFilter()}>
      <div className="filter__header filter-header">
        <div className="filter-header__title-block">
            <span className="filter-header__title">
              {FiltersFields[filter_field]}
            </span>
          <button
            type="button"
            onClick={() => handleResetUnitFilter()}
            className="button filter-header__button-reset">
            Сбросить
          </button>
        </div>
      </div>
      <div className="filter__content filter-content">

        <div className="filter-content__input-container"
             ref={ref}>
          <Dropdown
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
              <button
                // isSelected={isOpen}
                onClick={toggleOpen}
                className={"filter-select button " + (filter_data.length > 0 ? "" : "filter-select--empty" ) }>

                {filter_data.length !== 0
                ?
                  <span className="filter-select__label" style={!value ? {fontWeight: 400} : {}}>
                    {!value ? defaultValue : value }
                  </span>
                :
                  <span className="filter-select__label"/>
                }

                <span className="filter-select__icon-block">
                <img src={IMAGES.FilterIcon} className="filter-select__icon" alt=""/>
              </span>
              </button>
            }
          >
            <Select
              styles={customStyles}
              autoFocus
              backspaceRemovesValue={false}
              components={{DropdownIndicator}}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={onSelectChange}
              options={filter_data}
              placeholder="Найти"
              // className="select-filter-custom"
              tabSelectsValue={false}
              // value={valueSelect()}
            />
          </Dropdown>
        </div>
        <hr className="filter__line"/>

      </div>
    </div>
  );
};

export default FilterSelect;

const Menu = (props) => {
  return (
    <div className="filter-menu"
         {...props}
    />
  );
};

const Dropdown = ({children, isOpen, target, onClose}) => {

  return (
    <div className="filter-menu-dropdown">
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {/*{isOpen ? <Blanket onClick={onClose} /> : null}*/}
    </div>
  )
}

const Blanket = (props) => (
  <div
    className="filter-menu-blanket"
    {...props}
  />
);
