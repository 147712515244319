import React, {useEffect, useState} from 'react';

const useSizeScreen = () => {

  const [size,setSize] = useState([0,0])

  useEffect(()=>{
    const {innerWidth, innerHeight} = window
    setSize([innerWidth, innerHeight])
    window.addEventListener('resize', (e)=>{
      const {innerWidth, innerHeight} = e.target
      setSize([innerWidth, innerHeight])
    })
  },[])
  // console.log(size)
  return size

};

export default useSizeScreen;
