import React from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Col, Row} from "react-bootstrap";
import {colorSkeleton} from "../../constants";

const TableSkeleton = () => {
  return (

    <div className="cart-block">
      <Col lg={12} md={12} sm={12} xs={12}>
        <SkeletonTheme
          baseColor={colorSkeleton['baseColor']}
          highlightColor={colorSkeleton['highlightColor']}
        >
          <Skeleton count={4} height={35} style={{marginBottom: 5}}/>
        </SkeletonTheme>
      </Col>
    </div>

  );
};

export default TableSkeleton;
