import {
  TOGGLE_MODAL_THANKS,
  TOGGLE_MODAL_CALL,
  TOGGLE_MOBILE_MENU,
  TOGGLE_MODAL_DELETE,
  TOGGLE_MODAL_FILTER_MOBILE,
  TOGGLE_MODAL_CALL_MOBILE
} from "../constants";

export const toggleModalThanks = (bool) => ({type: TOGGLE_MODAL_THANKS, payload: bool})
export const toggleModalCall = (bool) => ({ type: TOGGLE_MODAL_CALL, payload: bool})
export const toggleModalCallMobile = (bool) => ({ type: TOGGLE_MODAL_CALL_MOBILE, payload: bool})
export const toggleMobileMenu = (bool) => ({ type: TOGGLE_MOBILE_MENU, payload: bool})
export const toggleModalDelete = (bool) => ({ type: TOGGLE_MODAL_DELETE, payload: bool})
export const toggleModalFilterMobile = (bool) => ({type: TOGGLE_MODAL_FILTER_MOBILE, payload: bool})
