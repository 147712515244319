import React, {useRef, createRef} from 'react';
import './choose-tyre.sass'
import Select, {components} from 'react-select'
import {IMAGES} from "../../constants/images";
import {useDispatch, useSelector} from "react-redux";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {colorSkeleton} from "../../constants";
import {Col} from "react-bootstrap";
import {FiltersFields} from "../../constants/filters";
import {createUrl} from "../../redux/actions/filters";
import {Link, useLocation} from "react-router-dom";

const ChooseTyre = (props) => {

  const filters = useSelector((state) => state.filters);

  const dispatch = useDispatch()
  const location = useLocation();
  const _url = filters.url !== null ? filters.url : location.search

  const ref = createRef();

  const onSelectChange = (data, filter) => {
    // if(data.value === null) ref?.current.clearValue()
    const _data = {
      value: data.value,
      filter_field: filter,
      type: 'select'
    }
    dispatch(createUrl(_data))
  };

  const options = (filter) => {
    let _options = [...filters.select[filter]]
    _options.unshift({value: null, label: 'Сбросить'})
    return _options
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={IMAGES.Icon2} alt="Открыть"/>
      </components.DropdownIndicator>
    );
  };

  const defaultVal =(filter)=>{
    let option = {value: '', label: ''}
    if(filters.select_value[filter].length > 0){
      if(filter === 'sections'){
        let section = filters?.select?.sections?.filter(item => +item.value === +filters.select_value[filter][0])[0]
        option.value = section?.value
        option.label = section?.label
      }else{
        option.value = filters?.select_value[filter][0]
        option.label = filters?.select_value[filter][0]
      }
    }else{
      option.value = FiltersFields[filter].toUpperCase()
      option.label = FiltersFields[filter].toUpperCase()
    }
    return option
  }

  return (
    <>
      {filters.loading_filter
        ?
        <SkeletonTheme
          baseColor={colorSkeleton['baseColor']}
          highlightColor={colorSkeleton['highlightColor']}>
          <Skeleton count={1} height={77} style={{marginTop: 0}}/>
        </SkeletonTheme>
        :
        <div className={`choose-tyre ${props.classBem}__choose-tyre`}>
          <div className="choose-tyre__block">
            {Object.keys(filters?.select).map((filter, index) => {
              if (filter === "diametrs"
                || filter === "widths"
                || filter === "heights"
                || filter === "sections") {
                return (
                  <div
                    style={{zIndex: 100 - index}}
                    className={"choose-tyre__input-block " + (filters?.select[filter].length === 0 ? "choose-tyre__input-block--disable" : "")}
                    key={index}>
                    <Select
                      ref={ref}
                      onChange={(data) => onSelectChange(data, filter)}
                      components={{DropdownIndicator}}
                      placeholder={FiltersFields[filter]}
                      classNamePrefix="choose-select"
                      className="choose-container"
                      options={options(filter)}
                      value={defaultVal(filter)}
                    />
                  </div>
                )
              }
            })}
          </div>
          <Link
            to={`catalog?${_url}`}
            disabled
            className="button button--red choose-tyre__button">
            <span>
              Подобрать
            </span>
          </Link>
        </div>
      }

    </>
  );
};

export default ChooseTyre;
