import React, {useRef, createRef, useState, useEffect} from 'react';
import {Navigation, Pagination, Scrollbar, A11y, Controller} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/controller';
import ModelCard from "../model-card/ModelCard";
import api from "../../api";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {colorSkeleton} from "../../constants";
import ProductCard from "../product-card/ProductCard";

const SliderModels = () => {

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const swiperRef = useRef(null)

  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.getPopularPModels().then(res => {
      setModels(res)
    }).catch(e=>{
      // console.log('SliderProduct - e -', e)
    }).finally(()=>{
      setIsLoading(false)
    })
  }, []);

  return (
    <div className="slider-models">
      <Swiper
        ref={swiperRef}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
        spaceBetween={30}
        loop={true}
        speed={800}
        slidesPerView={4}
        slidesPerGroup={1}
        breakpoints={{
          // when window width is >= 640px
          0: {
            // width: 0,
            slidesPerView: 1,
            slidesPerGroup: 1
          },
          575: {
            // width: 0,
            slidesPerView: 2,
            slidesPerGroup: 1
          },
          992: {
            // width: 992,
            slidesPerView: 3,
            slidesPerGroup: 1
          },
          1200: {
            // width: 1200,
            slidesPerView: 4,
            slidesPerGroup: 1
          },
          // when window width is >= 768px
          1440: {
            // width: 1440,
            slidesPerView: 4,
            slidesPerGroup: 1
          },
        }}
        pagination={{clickable: true}}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
      >

        {isLoading
          ?
          <SkeletonTheme
            baseColor={colorSkeleton['baseColor']}
            highlightColor={colorSkeleton['highlightColor']}>
            <Skeleton count={1} height={435}/>
          </SkeletonTheme>
          :
          models?.slice(0, 6)?.map((model, index) =>{
            return(
              <SwiperSlide
                key={index}>
                  <ModelCard
                    model={model}
                  />
              </SwiperSlide>
            )
          })
        }

      </Swiper>
      <div ref={navigationPrevRef} className="prev" onClick={() => swiperRef?.current?.swiper.slidePrev()}/>
      <div ref={navigationNextRef} className="next" onClick={() => swiperRef?.current?.swiper.slideNext()}/>
    </div>
  );
};

export default SliderModels;
