import {Link, useLocation} from "react-router-dom";
import {IMAGES} from "../constants/images";
import {Row, Col, Container} from "react-bootstrap";
import ChooseTyre from "../components/choose-tyre/chooseTyre";
import SliderModels from "../components/slider-models/SliderModels";
import AboutGrid from "../components/about-grid/aboutGrid";
import ContactMap from "../components/map/ContactMap";
import React, {useEffect} from "react";
import api from "../api";
import {loadingFilter, setFilter} from "../redux/actions/filters";
import {useDispatch, useSelector} from "react-redux";
import NavigationModel from "../components/navigation-model/NavigationModel";
import SliderMain from "../components/slider-models/SliderMain";
import {Helmet} from "react-helmet";
import PageTransition from "../components/PageTransition";

const MainPage = (props) => {

  const dispatch = useDispatch();

  const location = useLocation();

  const filters = useSelector((state) => state.filters);

  const _url = filters.url !== null ? filters.url : location.search;

  const init = async () => {
    await api.getFilter(_url).then((res) => {
      dispatch(setFilter(res));
      dispatch(loadingFilter(false));
    }).catch(e => {
      console.log(e);
    });
  };

  useEffect(() => {
    return () => {
      dispatch({type: "RESET_FILTER_VALUE"});
    };
  }, []);

  useEffect(() => {
    init();
  }, [_url]);
  // console.log(location)
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{props.title}</title>
      </Helmet>
      <Container fluid className="main-banner">

        <Link to="/contacts/invite" className="main-banner__invite">
          <img
            className="main-banner__invite-image"
            src={IMAGES.Invite}
            alt="Приглашение" />
        </Link>



        <SliderMain/>

        <Container>
          <Row>
            <Col>
              {/*<div className="main-banner__title-block title-bg">*/}
              {/*  <h1 className="main-banner__title">Для сельхозтехники</h1>*/}
              {/*  <p className="main-banner__sub-title">*/}
              {/*    Мощные механизмы не могут обойтись без надёжных и качественных шин*/}
              {/*  </p>*/}
              {/*</div>*/}
              <ChooseTyre classBem="main-banner"/>
            </Col>
          </Row>

        </Container>
      </Container>

      <Container fluid className="navigation-area d-md-block d-none">
        <Container>
          <ul className="navigation">
            <li className="navigation__item">
              <Link to="/about/technologies" className="navigation__link">
                Технологии
              </Link>
            </li>
            <li className="navigation__item">
              <Link to="/about/about-company" className="navigation__link">
                О компании
              </Link>
            </li>
            <li className="navigation__item">
              <Link to="/delivery" className="navigation__link">
                Доставка и оплата
              </Link>
            </li>
            <li className="navigation__item">
              <Link to="/contacts/contacts-company" className="navigation__link">
                Контакты
              </Link>
            </li>
          </ul>
        </Container>
      </Container>

      <NavigationModel/>

      <Container fluid className="models-area">
        <Container>
          <div className="title-block">
            <h2 className="title">Популярные модели</h2>
            {/*<Link to="/" className="title-block__link">*/}
            {/*  Смотреть все*/}
            {/*</Link>*/}
          </div>
          <SliderModels/>
        </Container>
      </Container>

      <Container fluid className="main-about">
        <Container>
          <Row>
            <Col xl={6}>
              <h2 className="main-about__title title-line">О производителе</h2>
              <h3 className="main-about__sub-title">
                PETLAS Tire Industry Co.
              </h3>
              <p className="main-about__info">
                <strong> STARMAXX </strong> является торговой маркой компании
                <strong> PETLAS Tire Industry Co. </strong>
                Ассортимент шин Starmaxx представлен покрышками для различных
                категорий транспорта и специализированной техники – от легковых
                автомобилей до индустриальных машин. Основной линией
                производства считаются шины для оснащения вилочных погрузчиков,
                самосвалов, грузового транспорта и спецтехники. PETLAS Tire
                Industry Co. имеет сертификаты обеспечения качества системы ISO
                9001 и промышленного обеспечения качества AQAP-110. Благодаря
                высоким показателям качества покрышки данного производителя в
                минимальной степени подвержены изломам отдельных их частей при
                воздействии негативных факторов условий работы спецтехники. Шины
                Starmaxx экспортируются в более чем сто стран мира и имеют
                широкую и стабильную дистрибьюторскую сеть на мировом рынке шин.
              </p>
              <div className="main-about__button-area button--skew">
                <Link
                  to="/about/about-company"
                  className="button  button--blue main-about__button-blue"
                >
                  <span>подробнее о компании</span>
                </Link>
              </div>
            </Col>
            <Col xl={6}>
              <AboutGrid/>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="delivery-area bg-gray">
        <Container>
          <h2 className="delivery-area__title">Доставка и оплата</h2>

          <div className="delivery">
            <div className="delivery__item ">
              <img src={IMAGES.Image1} alt="" className="delivery__arrow"/>
              <span className="delivery__title">выбор товара</span>
              <img
                className="delivery__image"
                src={IMAGES.Delivery1}
                alt="С чего начать"
              />
              <p className="delivery__text">
                Выберите интересующий
                вас товар
              </p>
              <div className="delivery__list">
                <p>на сайте</p>
                <p>консультация по телефону</p>
                {/*<p>*/}
                {/*  банковский перевод*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*  через Сбербанк онлайн*/}
                {/*</p>*/}
              </div>
            </div>
            {/*<div className="delivery__item delivery__item--i2">*/}
            {/*  <img src={IMAGES.Image1} alt="" className="delivery__arrow"/>*/}
            {/*  <span className="delivery__title">наличие товара</span>*/}
            {/*  <img*/}
            {/*    className="delivery__image"*/}
            {/*    src={IMAGES.Delivery2}*/}
            {/*    alt="наличие товара"*/}
            {/*  />*/}
            {/*  <p className="delivery__text ">*/}
            {/*    После того, как заказ будет оформлен, с вами свяжется менеджер*/}
            {/*  </p>*/}
            {/*</div>*/}
            <div className="delivery__item ">
              <img src={IMAGES.Image1} alt="" className="delivery__arrow"/>
              <span className="delivery__title">Оплата</span>
              <img
                className="delivery__image"
                src={IMAGES.Delivery3}
                alt="наличие товара"
              />
              <p className="delivery__text">
                Оплата производится любым из перечисленных способов
              </p>
              <div className="delivery__list">
                <p>безналичный расчет
                  <br/>( для юр.лиц )</p>
                <p>банковский перевод</p>
                <p>
                  наличный расчет
                </p>
              </div>
            </div>
            <div className="delivery__item ">
              <span className="delivery__title">доставка</span>
              <img
                className="delivery__image"
                src={IMAGES.Delivery5}
                alt="наличие товара"
              />
              <p className="delivery__text">
                Получить товар вы сможете
                несколькими способами
              </p>
              <div className="delivery__list">
                <p>с помощью любых тк</p>
                <p>с помощью нашего транспорта</p>
                <p>самовывоз</p>
              </div>
            </div>
          </div>

          {/*<div className="delivery-info-list">*/}
          {/*  <div className="delivery-info">*/}
          {/*    <img*/}
          {/*      src={IMAGES.DeliveryInfo3}*/}
          {/*      alt=""*/}
          {/*      className="delivery-info__bg"*/}
          {/*    />*/}
          {/*    <div className="delivery-info__title">Самовывоз</div>*/}
          {/*    <p className="delivery-info__text">*/}
          {/*      Время работы склада и магазина: в рабочие дни с 9-00 до 18-00.*/}
          {/*      Доставка по транспортным компаниям:*/}
          {/*      пн-пт с 11-00 до 17-00.*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*  <div className="delivery-info">*/}
          {/*    <img*/}
          {/*      src={IMAGES.DeliveryInfo4}*/}
          {/*      alt=""*/}
          {/*      className="delivery-info__bg"*/}
          {/*    />*/}
          {/*    <div className="delivery-info__title">Доставка ТК</div>*/}
          {/*    <p className="delivery-info__text">*/}
          {/*      Стоимость доставки по г.Москва (в пределах МКАД 2 000 руб.).*/}
          {/*      Наша компания производит доставку товара по*/}
          {/*      всей территории России.*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="d-flex justify-content-center">
            <Link
              to="/delivery"
              className="button button--blue delivery-area__button-blue"
            >
              <span>подробнее о доставке</span>
            </Link>
          </div>
        </Container>
      </Container>

      <Container fluid className="contact-area">
        <Container className="contact-area__block">
          <div className="title-block">
            <h2 className="delivery-area__title title-line">Контакты</h2>
            <Link to="/contacts/contacts-company" className="title-block__link">
              Смотреть все
            </Link>
          </div>
          <p className="contact-area__info">
            Рассчет стоимости доставки осуществляется у представителей транспортной компании или вашего персонального менеджера.
            Доставка вашего товара транспортными компаниями осуществляется по территории России и в страны СНГ.
            {/*<img src={IMAGES.Ru} alt="Россия"/> России и{" "}*/}
            {/*<img src={IMAGES.T} alt="Турция"/> Турции.*/}
          </p>
        </Container>
      </Container>

      {/*<Container fluid className="contact-desc">*/}
      {/*  <Container className="contact-desc__info">*/}
      {/*    <p>*/}
      {/*      Доставка вашего товара транспортными компаниями осуществляется по*/}
      {/*      территории России и в страны СНГ. Рассчет стоимости доставки*/}
      {/*      осуществляется у представителей транспортной компании или вашего*/}
      {/*      персонального менеджера.*/}
      {/*    </p>*/}
      {/*  </Container>*/}
      {/*</Container>*/}

      <ContactMap withLink={true}/>

    </>
  );
};

export default MainPage;
