import React from "react";
import {Container} from "react-bootstrap";
import {IMAGES} from "../constants/images";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import PageTransition from "../components/PageTransition";

const ErrorPage = (props) => {
  return (
    <>
      <PageTransition>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{props.title}</title>
        </Helmet>
        <Container fluid className="error-page">
          <img src={IMAGES.ErrorPageBg} alt="" className="error-page__bg"/>
          <Container fluid="xl" className="error-page__container">
            <img
              src={IMAGES.Logo}
              alt="starmaxx"
              width="530"
              height="64"
              className="error-page__logo"
            />
            <h2 className="error-page__title">
              Страница не найдена
            </h2>
            <p className="error-page__code">
              404
            </p>
            <p className="error-page__info">
              Неправильно набран адрес
              <br/>
              или такой страницы не существует.
            </p>
            <Link to="/" className="button button--red error-page__button-red">
            <span>
              вернуться на сайт
            </span>
            </Link>
          </Container>
        </Container>
      </PageTransition>
    </>
  );
};

export default ErrorPage;
