export const ConvertPriceFromStringInNumber = (price) => {
  if (price === undefined || price === null) return 0;
  return parseInt(price.replace(/\s/g, ""), 10).toLocaleString();
};

export const Price = (price) => {
  // console.log('price',price)
  if (price === undefined || 0) return;
  return price
    .toString()
    .split("")
    .reverse()
    .map((el, index) => (index % 3 !== 2 ? el : ` ${el}`))
    .reverse()
    .join("");
};

// склонение слов
export const Declination = (number, titles) => {
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const GetLocalStorage = (name, state) => {
  return localStorage.getItem(name)
    ? JSON.parse(localStorage.getItem(name))
    : state;
};
