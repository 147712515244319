import React from 'react';
import Filter from "../filter/filter";
import {useSelector} from "react-redux";

const MobileFilter = () => {
  const modals = useSelector((state) => state.modals);
  return (
    <div className="mobile-filter-area d-lg-none d-block">
      <div className={"mobile-filter " + (modals.modal_filter_mobile ? "active" : "")}>
        <Filter/>
      </div>
    </div>
  );
};

export default MobileFilter;
