import {Fragment} from "react";
import Header from "../components/header/Header";
import DropCart from "../components/header/drop-cart/DropCart";
import ThanksModal from "../components/thanks-modal/ThanksModal";
import Footer from "../components/footer/Footer";
import {Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import MobileLeftMenu from "../components/mobile-left-menu/MobileLeftMenu";
import ModalDelete from "../components/modal-delete/ModalDelete";
import MobileFilter from "../components/mobile-filter/MobileFilter";
import useSizeScreen from "../hooks/useSizeScreen";
import Cookie from "../components/сookie/Cookie";

const MainLayout = ({children}) => {
  const modals = useSelector((state) => state.modals);

  const cart = useSelector((state) => state.cart);

  useSizeScreen();

  return (
    <>
      <div className="container-fluid">
        <MobileLeftMenu/>
        <MobileFilter/>
        <Header/>

        {children}

        <Footer/>

        <Modal
          centered
          show={cart.modal}>
          <DropCart/>
        </Modal>

        <Modal
          centered
          size="sm"
          show={modals.modalThanks}>
          <ThanksModal/>
        </Modal>

        <Modal
          centered
          size="sm"
          show={modals.modal_delete}>
          <ModalDelete/>
        </Modal>

      </div>
      <Cookie/>
    </>
  );
};

export default MainLayout;
