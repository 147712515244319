import React, {useRef, useState, useEffect} from 'react';
import {Navigation, Pagination, Scrollbar, A11y, Controller} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/controller';
import ProductCard from "../product-card/ProductCard";
import api from '../../api/index'
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {colorSkeleton} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {setPopularProduct} from "../../redux/actions/products";

const SliderProduct = () => {
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const swiperRef = useRef(null)
  const dispatch = useDispatch()

  const products = useSelector((state) => state.products);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.getPopularProducts().then(res => {
      dispatch(setPopularProduct(res))
    }).catch(e=>{
      // console.log('SliderProduct - e -', e)
    }).finally(()=>{
      setIsLoading(false)
    })
  }, []);

  return (
    <div className="slider-models">
      <Swiper
        ref={swiperRef}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
        spaceBetween={30}
        loop={true}
        speed={800}
        slidesPerView={4}
        slidesPerGroup={1}
        breakpoints={{
          // when window width is >= 640px
          0: {
            // width: 0,
            slidesPerView: 1,
          },
          992: {
            // width: 992,
            slidesPerView: 2,
          },
          1200: {
            // width: 1200,
            slidesPerView: 3,
          },
          // when window width is >= 768px
          1440: {
            // width: 1440,
            slidesPerView: 4,
          },
        }}
        pagination={{clickable: true}}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
      >

        {isLoading
        ?
          <SkeletonTheme
            baseColor={colorSkeleton['baseColor']}
            highlightColor={colorSkeleton['highlightColor']}>
            <Skeleton count={1} height={435}/>
          </SkeletonTheme>
        :
          products?.popular_product.slice(0, 6)?.map(product =>{
            return(
              <SwiperSlide key={product.id}>
                <ProductCard  product={product}/>
              </SwiperSlide>
            )
          })
        }

      </Swiper>
      <div ref={navigationPrevRef} className="prev" onClick={() => swiperRef?.current?.swiper.slidePrev()}/>
      <div ref={navigationNextRef} className="next" onClick={() => swiperRef?.current?.swiper.slideNext()}/>
    </div>
  );
};

export default SliderProduct;
