import React, {useCallback, useEffect} from "react";
import {IMAGES} from "../constants/images";
import {useNavigate, useLocation} from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {colorSkeleton, sections} from "../constants";
import api from "../api/index";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import ProductCard from "../components/product-card/ProductCard";
import {useDispatch, useSelector} from "react-redux";
import {
  changeFilterType,
  createUrl,
  createUrlRange,
  loadingFilter,
  resetFilter,
  setFilter,
  setPagination
} from "../redux/actions/filters";
import {FiltersFields} from "../constants/filters";
import {Declination} from "../utils/helpers";
import {loadingProducts, setProductByModelAction} from "../redux/actions/products";
import FilterCheckbox from "../components/filter/filterCheckbox";
import FilterRange from "../components/filter/filterRange";
import queryString from "query-string";
import Pagination from "../components/pagination/pagination";
import Filter from "../components/filter/filter";
import {Helmet} from "react-helmet";
import {toggleModalFilterMobile} from "../redux/actions/modals";
import ModelBanner from "../components/model-banner/ModelBanner";
import FiltersBubbles from "../components/filters-bubbles/FiltersBubbles";
import PageTransition from "../components/PageTransition";

const CatalogPage = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const filters = useSelector((state) => state.filters);

  const products = useSelector((state) => state.products);

  const handleOpenFilterMobile = () => {
    dispatch(toggleModalFilterMobile(true));
  };

  const handleCreateUrl = () => {
    Object.keys(parsed).forEach(key => {
      let _data = {
        value: parsed[key] !== "" ? parsed[key].toString() : "",
        filter_field: key,
        type: ""
      };

      if (filters.select[key]) {
        _data.type = "select";
      }
      if (filters.checkbox[key]) {
        _data.type = "checkbox";
      }
      if (key === "sort") {
        _data.type = "sort";
      }
      if (key === "limit") {
        _data.type = "pagination";
      }
      if (key === "offset") {
        _data.type = "pagination";
      }
      if (key === "index_size") {
        dispatch(changeFilterType("P"));
      }
      if (key === "type") {
        _data.type = "type";
      }
      if (filters.select[key]) {
        _data.type = "select";
      }
      if (key === "search") {
        _data.type = "search";
      }
      // dispatch(createUrl(_data))
      dispatch({type: "PARSE_URL", payload: _data});
    });

    const _data = {
      value: [0, 0],
      filter_field: "prices",
      type: "range"
    };

    if (parsed["price_from"]) _data.value[0] = parsed["price_from"];
    if (parsed["price_to"]) _data.value[1] = parsed["price_to"];

    if (_data.value[0] !== 0 || _data.value[1] !== 0) {
      //dispatch(createUrl(_data))
      dispatch({type: "PARSE_URL", payload: _data});
    }

    // dispatch(createUrl(_data))
  };

  const _url = filters.url;

  // console.log('render')

  // const [page, setPage] = useState(1)
  // const _page = parsed.page ? parsed.page : page

  useEffect(() => {
    handleCreateUrl();
    return () => {
      // console.log('exit')
      dispatch({type: "RESET_FILTER_VALUE"});
      // dispatch(resetFilter())
    };
  }, []);

  useEffect(() => {
    // setPage(_page)
    init(`${_url}`);
    navigate(`/catalog?${_url}`);
  }, [_url]);

  const init = async (_url) => {
    dispatch(loadingProducts(true));
    await api.getFilter(_url).then((res) => {
      dispatch(setFilter(res));
      dispatch(loadingFilter(false));
    }).catch(e => {
      console.log(e);
    });
    await api.getProducts(_url).then((res) => {
      dispatch(setProductByModelAction(res));
      let _pagination = {};
      // конвертируем все поля в числа и получаем новую пагинацию
      Object.keys(res.paginate).forEach(key => {
        _pagination[key] = +res.paginate[key];
      });
      dispatch(setPagination(_pagination));
    }).catch(e => {
      console.log(e);
    });
    dispatch(loadingProducts(false));
  };

  const handleSort = (data) => {
    const _data = {
      value: data,
      filter_field: "sort",
      type: "sort"
    };
    dispatch(createUrl(_data));
  };

  const handleLimit = (data) => {
    const _data = {
      value: data,
      filter_field: "limit",
      type: "pagination"
    };
    dispatch(createUrl(_data));
  };

  const generationSection = (id) => {

    let section = undefined;

    if (+id === 14) {
      section = sections["agricultural"];
    }
    if (+id === 18) {
      section = sections["trucks"];
    }
    if (+id === 27) {
      section = sections["special"];
    }

    return section;
  };

  const handlerResetAll = () => {
    dispatch({type: "RESET_FILTER_VALUE"});
  };

  const catalogTitle = () => {
    let section = 0;

    const params = location.search.trim().replace("?", "").split("&");

    params.forEach((param) => {
      const value = param.split("=");

      if (value[0] === "sections") {
        section = +value[1];
      }
    });

    switch (section) {
      case 14:
        return "Шины для сельхозтехники";
      case 18:
        return "Шины для грузовой техники";
      case 27:
        return "Шины для спецтехники";

      default:
        return "Все шины";
    }
  };

  // console.log(products?.products)

  return (

      <section className="cart-page">
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{catalogTitle()}</title>
        </Helmet>

        <ModelBanner
          type="products"
          section={generationSection(parsed?.sections)}
        />

        <Breadcrumbs/>

        <Container fluid className="cart-area cart-area-bg">
          <Container className="cart-area__container">

            <div className="cart-block">
              <div className="cart-block__title-block"><h2 className="cart-block__title">найдено</h2>
                {products.loadingProducts
                  ?
                  ""
                  :
                  <div className="count-product">
                  <span className="count-product__count">
                  {products.loadingProducts
                    ?
                    ""
                    :
                    filters?.pagination?.total
                  }
                </span>
                    {" "}{Declination(filters.count, ["товар", "товара", "товаров"])}
                  </div>
                }

              </div>
              {/*<h3 style={{zIndex: 10}}>*/}
              {/*  {location.pathname + location.search}*/}
              {/*  <hr/>*/}
              {/*  {filters.url}*/}
              {/*  <hr/>*/}
              {/*</h3>*/}
              <div>
                <Row>

                  <Col xxl={3} xl={3} lg={4} className="d-lg-block d-none">
                    <Filter/>
                  </Col>

                  <Col xxl={9} xl={9} lg={8}>
                    <div className="area d-flex justify-content-between flex-wrap position-relative">
                      <div className="catalog-view-type-sort">
                        <span>Сортировать по:</span>
                        <button type="button" //to={`?${_url}`}
                                onClick={() => handleSort(null)}
                                title="Обновлению"
                                className={"button catalog-view-type-sort__item " + (filters.sort === "" ? "catalog-view-type-sort__item--active" : "")}>
                          Обновлению
                        </button>
                        <button type="button" //to={`?${_url}`}
                                onClick={() => handleSort("asc")}
                                title="Сначала дешевле"
                                className={"button catalog-view-type-sort__item " + (filters.sort === "asc" ? "catalog-view-type-sort__item--active" : "")}>
                          Сначала дешевле
                        </button>
                        <button type="button" //to={`?${_url}`}
                                onClick={() => handleSort("desc")}
                                title="Сначала дороже"
                                className={"button catalog-view-type-sort__item " + (filters.sort === "desc" ? "catalog-view-type-sort__item--active" : "")}>
                          Сначала дороже
                        </button>
                      </div>
                      <div className="catalog-view-type-sort catalog-limit ">
                        <span>Показывать по:</span>
                        <button type="button"
                                onClick={() => handleLimit(6)}
                                title="Показать 6 товара"
                          //to={`?${_url}`}
                                className={"button catalog-limit__item " + (filters.pagination.limit === 6 ? "catalog-limit__item--active" : "")}>
                          6
                        </button>
                        <button type="button"
                                onClick={() => handleLimit(12)}
                                title="Показать 12 товаров"
                          //to={`?${_url}`}
                                className={"button catalog-limit__item " + (filters.pagination.limit === 12 ? "catalog-limit__item--active" : "")}>
                          12
                        </button>
                        <button type="button"
                                onClick={() => handleLimit(24)}
                                title="Показать 24 товара"
                                to={`?${_url}`}
                                className={"button catalog-limit__item " + (filters.pagination.limit === 24 ? "catalog-limit__item--active" : "")}>
                          24
                        </button>
                      </div>
                    </div>

                    <div>
                      <button className="button d-lg-none d-flex button--blue filter-button" type="button"
                              onClick={() => handleOpenFilterMobile()}>
                        <span>Фильтр</span>
                        <img src={IMAGES.Filter} alt="Фильтр"/>
                        <span className="count">
                        {filters.count_filter}
                      </span>
                      </button>
                    </div>

                    <FiltersBubbles/>

                    <Row>
                      {products.loadingProducts
                        ?
                        Array(6).fill("").map((_, index) => {
                          return (
                            <Col key={index} xxl={4} xl={4} lg={6} md={6} sm={12}>
                              <SkeletonTheme
                                baseColor={colorSkeleton["baseColor"]}
                                highlightColor={colorSkeleton["highlightColor"]}>
                                <Skeleton count={1} height={410} style={{marginBottom: 30}}/>
                              </SkeletonTheme>
                            </Col>
                          );
                        })
                        :
                        <>
                          {
                            products?.products?.length === 0
                              ?
                              <Col>
                                <div className="cart-stub p-0 mt-20">
                                  <p className="cart-stub__text text-center mb-10">
                                    <span className="black">Шины не найдены</span>
                                  </p>
                                  <button
                                    onClick={() => handlerResetAll()}
                                    className="button filter-reset"
                                  >
                                    <span>Сбросить параметры поиска</span>
                                  </button>
                                </div>
                              </Col>
                              :
                              products.products.map((product, index) => {
                                return (
                                  <Col xxl={4} xl={4} lg={6} md={6} sm={12} key={index} className="col-product-card">
                                    <ProductCard
                                      product={product}
                                    />
                                  </Col>
                                );
                              })
                          }
                        </>
                      }
                    </Row>

                    <Pagination
                      location={location}
                      pagination={filters.pagination}
                    />

                  </Col>

                </Row>
              </div>
            </div>

          </Container>

        </Container>

      </section>

  );
};

export default CatalogPage;
