import React from 'react';
import {FiltersFields} from "../../constants/filters";
import {createUrl} from "../../redux/actions/filters";
import {useDispatch, useSelector} from "react-redux";

const FilterCheckbox = ({filter_field, filter_data}) => {
  // console.log(filter_data)

  const dispatch = useDispatch()
  const filters = useSelector((state) => state.filters);
  const onSelectChange = (data) => {
    const _data = {
      value: data.id.toString(),
      filter_field: filter_field,
      type: 'checkbox'
    }
    dispatch(createUrl(_data))
  };
  const handleResetUnitFilter = () => {
    const _data = {
      value: null,
      filter_field: filter_field,
      type: 'checkbox'
    }
    dispatch(createUrl(_data))
  }

  return (
    <div className="filter">
      <div className="filter__header filter-header">
        <div className="filter-header__title-block">
            <span className="filter-header__title">
              {FiltersFields[filter_field]}
            </span>
          <button
            type="button"
            onClick={() => handleResetUnitFilter()}
            className="button filter-header__button-reset">
            Сбросить
          </button>
        </div>
      </div>
      <div className="filter-checkbox-content filter__content filter-content">
        {filter_data.map((filter, index) => {
          return (
            <label
              key={index}
              className="form-check form-check-filter agreement">
              <input
                checked={filters?.checkbox_value[filter_field].indexOf(filter.id.toString()) !== -1}
                onChange={() => onSelectChange(filter)}
                className="form-check-input"
                type="checkbox"
              />
              <div className="checkbox"/>
              {filter.name}
            </label>
          )
        })}
        <hr className="filter__line"/>
      </div>
    </div>
  );
};

export default FilterCheckbox;
