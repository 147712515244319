import React, {useState} from 'react';
import {Container} from "react-bootstrap";
import api from "../../../api";
import {toggleModalThanks} from "../../../redux/actions/modals";
import {useDispatch} from "react-redux";
import {IMAGES} from "../../../constants/images";

const FormCommunicationManager = () => {

  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault()
    const newErrors = {};

    // if (!name) newErrors["name"] = "Введите имя";
    if (!email) newErrors["email"] = "Введите e-mail";
    // if (!comment) newErrors["comment"] = "Введите комментарий";
    // if (!isAgree) newErrors["isAgree"] = "Дайте согласие на обработку данных";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    await api.postFeedback({
      // name,
      email,
      // comment
    });

    // setComment('');
    setEmail('');
    // setName('');

    dispatch(toggleModalThanks(true));
  }
  return (
    <Container fluid className="connection">
      <Container className="connection__container">
        <div className="connection-form">
            <span className="connection-form__text">
              Свяжитесь с нами! Наш менеджер ответит вам в течение нескольких минут.
            </span>
          <form
            onSubmit={onSubmit}
            className="connection-form__input-container">
            <div className="d-flex">
              <input
                value={email || ''}
                onChange={e => {
                  setEmail(e.target.value)
                }}
                placeholder="Введите свой email"
                type="text"
                className="form-control connection-form__form-control"/>
              <button className="button button--blue connection-form__button-blue">
                <span>Отправить</span>
              </button>
            </div>
            {errors.email && (
              <div className="modal-order-call__form-error">
                <img src={IMAGES.ErrorRed} alt="!"/> {errors.email}
              </div>
            )}
          </form>
        </div>
      </Container>
    </Container>
  );
};

export default FormCommunicationManager;
