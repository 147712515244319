import {
  SELECT_PAYMENT_METHOD, MAKING_ORDER,
  GET_MAKING_ORDER, CHANGE_FORM_INPUT,
  RESET_MAKING_ORDER, SET_ERRORS, RESET_ERRORS, ORDER_ACCESS
} from "../constants";

export const getMakingOrder =()=>({type: GET_MAKING_ORDER})
export const selectPaymentMethod =(data)=>({type: SELECT_PAYMENT_METHOD, payload: data})
export const makingOrder =()=>({type: MAKING_ORDER})
export const changeFromInput =(data)=>({type: CHANGE_FORM_INPUT, payload: data})
export const resetMakingOrder =()=>({type: RESET_MAKING_ORDER})
export const setErrors = (field,value)=>({type: SET_ERRORS, payload: {field,value}})
export const resetErrors = ()=>({type: RESET_ERRORS})
export const orderAccess = (data)=>({type: ORDER_ACCESS, payload: data})
