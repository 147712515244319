import {useEffect, useRef, useState, Fragment} from "react";
import {Link} from "react-router-dom";
import {YMaps, Map, Placemark} from "react-yandex-maps";
import {Row, Col, Container} from "react-bootstrap";
import MapIcon from "../../assets/icons/map-icon.png";
import {MAIL, PHONE, PHONE_LINK} from "../../constants";
import {IMAGES} from "../../constants/images";
import "./contactMap.sass";

const ContactMap = () => {
  const [mapState, setMapState] = useState({
    center: [49.543322, 31.254857],
    zoom: 4.2,
    behaviors: ["drag", "dblClickZoom", "scrollZoom", "multiTouch"]
  });

  const markers = [
    {
      id: 1,
      country: "Россия",
      contact: {
        email: MAIL,
        phone: PHONE
      },
      points: [
        {
          id: 1,
          title: "Москва",
          address: "Московская область, г. Подольск, пос. Подольской МИС, ул. Промышленная, 2",
          coords: [55.342048, 37.547737]
        },
        {
          id: 2,
          title: "Орел",
          address: "ул. Московское шоссе д. 173",
          coords: [53.014796, 36.156400]
        },
        {
          id: 3,
          title: "Ростов-на-Дону",
          address: "г. Батайск ул. Комарова д. 181A",
          coords: [47.119537, 39.769738]
        },
        {
          id: 4,
          title: "Новосибирск",
          address: "улица Петухова 27/3",
          coords: [54.955189, 82.852661]
        },
        {
          id: 5,
          title: "Республика Татарстан",
          address: "г.Набережные Челны, Мензелинский тракт д.52, офис 505",
          coords: [55.695119, 52.415987]
        },
        {
          id: 6,
          title: "Санкт-Петербург",
          address: "Софийская улица, 121к4, посёлок Петро-Славянка, Колпинский район",
          coords: [59.808500, 30.459311]
        }
      ]
    },
    {
      id: 2,
      country: "Турция",
      contact: {
        email: null,
        phone: null
      },
      points: [
        {
          id: 1,
          title: null,
          address: "г. Исламбей, ул. Меджидие, 2/1",
          coords: [41.187211, 28.741517]
        }
      ]
    }
  ];

  const map_ref = useRef();

  const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
  let [width, setWidth] = useState(getWidth());

  const handleClick = (item, point) => {
    const newMapState = {
      center: markers[item - 1].points[point - 1].coords,
      zoom: 15,
      behaviors: ["drag", "dblClickZoom", "scrollZoom", "multiTouch"]
    };
    if (width <= 768) {
      map_ref?.current?.scrollIntoView({
        block: "start",
        behavior: "smooth"
      });
    }
    setMapState(newMapState);
  };

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth());
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const markerList = markers.map((item, index) => {
    return (
      <Fragment key={item.id}>
        <div className="contact-map-item">
          <div className="contact-map-item__location">
            {item.country}
          </div>

          {item.contact.email || item.contact.phone
            ?
            <ul className="contact-map-item__contacts">
              {item.contact.phone
                &&
                <li className="contact-map-item__contact">
                  <img
                    className="contact-map-item__contact-icon"
                    src={IMAGES.Icon4} alt="Phone" />
                  <Link
                    className="contact-map-item__contact-phone"
                    to="#"
                    onClick={(e) => {
                      window.location.href = `tel:${PHONE_LINK}`;
                      e.preventDefault();
                    }}
                    dangerouslySetInnerHTML={{__html: item.contact?.phone}}
                  >
                  </Link>
                </li>
              }
              {item.contact.email
                &&
                <li className="contact-map-item__contact">
                  <img
                    className="contact-map-item__contact-icon"
                    src={IMAGES.Icon5} alt="Email" />
                  <Link
                    className="contact-map-item__contact-email"
                    to="#"
                    onClick={(e) => {
                      window.location.href = `mailto:${item.contact.email}`;
                      e.preventDefault();
                    }}
                  >
                    {item.contact.email}
                  </Link>
                </li>
              }
            </ul>
            :
            ""
          }

          {item.points.map(point => {
            return (
              <div className="contact-map-region" key={point.id}>
                {point.title
                  ?
                  <div className="contact-map-region__name">
                    {point.title}
                  </div>
                  :
                  ""
                }

                <ul className="contact-map-region__list" onClick={e => handleClick(item.id, point.id)}>
                  <li className="contact-map-region__item">
                    {point.address}
                  </li>
                </ul>
              </div>
            );
          })}
        </div>
        {index !== markers.length - 1
          &&
          <hr className="contact-map-line" />
        }
      </Fragment>
    );
  });

  return <Container fluid className="contact-map">
    <YMaps>

      <div className="contact-map__content">
        <Container>
          <Row>
            <Col xl={4}>
              <div className="contact-map__addresses">
                <div className="contact-map__addresses-scroll">
                  <div className="contact-map__addresses-title">
                    PETLAS Tire Industry
                  </div>
                  <div className="contact-map__list">
                    {markerList}
                  </div>
                </div>
              </div>
              <span
                style={{opacity: 0}}
                ref={map_ref}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Map
        className="map"
        state={mapState}
        width="100%"
        // height="560px"
      >
        {markers.map((marker) => {
          return marker.points.map((point) => {
            return <Placemark
              key={point.id}
              geometry={point.coords}
              options={{
                iconLayout: "default#image",
                iconImageSize: [47, 53],
                iconImageHref: MapIcon,
                iconImageOffset: [-23, -53]
              }}
            />;
          });
        })}
      </Map>

    </YMaps>

  </Container>;
};

export default ContactMap;
