import {Button, Modal} from "react-bootstrap";
import React, {Fragment, useState} from "react";
import DropCart from "../components/header/drop-cart/DropCart";

const Test = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      {/*<Button variant="primary" onClick={handleShow}>*/}
      {/*  Launch static backdrop modal*/}
      {/*</Button>*/}
      {/*<Modal show={show} onHide={handleClose}>*/}
      {/*  <DropCart/>*/}
      {/*</Modal>*/}
    </div>
  );
};

export default Test;
