import React, {useRef, createRef} from 'react';
import {Navigation, Pagination, Scrollbar, A11y, Controller, EffectCoverflow} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/controller';
import "swiper/css/effect-coverflow";
import ModelCard from "../model-card/ModelCard";
import {IMAGES} from "../../constants/images";
import Fancybox from "../../hooks/Fancybox";

const SliderCertificate = () => {

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const swiperRef = useRef(null)

  return (
    <div className="slider-models slider-certificates">
      <Swiper
        effect={"coverflow"}
        ref={swiperRef}
        centeredSlides={true}
        parallax={true}
        grabCursor={true}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 80,
          depth: 60,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[Navigation, Pagination, Scrollbar, A11y, Controller, EffectCoverflow]}
        spaceBetween={0}
        slidesPerView={'auto'}
        loop={true}
        speed={800}
        // breakpoints={{
        //   // when window width is >= 640px
        //   0: {
        //     // width: 0,
        //     slidesPerView: 1,
        //   },
        //   992: {
        //     // width: 992,
        //     slidesPerView: 2,
        //   },
        //   1200: {
        //     // width: 1200,
        //     slidesPerView: 3,
        //   },
        //   // when window width is >= 768px
        //   1440: {
        //     // width: 1440,
        //     slidesPerView: 3,
        //   },
        // }}
        pagination={{clickable: true}}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {/*<SwiperSlide>*/}

        {/*</SwiperSlide>*/}

        <SwiperSlide>
          <Fancybox options={{infinite: false}}>
            <img src={IMAGES.Sert1} alt=""
                 data-fancybox="gallery"
                 data-src={IMAGES.Sert1}
            />
          </Fancybox>
        </SwiperSlide>

        <SwiperSlide>
          <Fancybox options={{infinite: false}}>
            <img src={IMAGES.Sert2} alt="" data-fancybox="gallery"
                 data-src={IMAGES.Sert2}/>
          </Fancybox>
        </SwiperSlide>

        <SwiperSlide>
          <Fancybox options={{infinite: false}}>
            <img src={IMAGES.Sert3} alt="" data-fancybox="gallery"
                 data-src={IMAGES.Sert3}/>
          </Fancybox>
        </SwiperSlide>

        <SwiperSlide>
          <Fancybox options={{infinite: false}}>
            <img src={IMAGES.Sert4} alt="" data-fancybox="gallery"
                 data-src={IMAGES.Sert4}/>
          </Fancybox>
        </SwiperSlide>

        <SwiperSlide>
          <Fancybox options={{infinite: false}}>
          <img src={IMAGES.Sert5} alt="" data-fancybox="gallery"
               data-src={IMAGES.Sert5}/>
          </Fancybox>
        </SwiperSlide>

        <SwiperSlide>
          <Fancybox options={{infinite: false}}>
            <img src={IMAGES.Sert6} alt="" data-fancybox="gallery"
                 data-src={IMAGES.Sert6}/>
          </Fancybox>
        </SwiperSlide>
        {/*<SwiperSlide>*/}

        {/*</SwiperSlide>*/}
      </Swiper>
      <div ref={navigationPrevRef} className="prev" onClick={() => swiperRef?.current?.swiper.slidePrev()}/>
      <div ref={navigationNextRef} className="next" onClick={() => swiperRef?.current?.swiper.slideNext()}/>
    </div>
  );
};

export default SliderCertificate;
