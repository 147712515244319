import React, {useState} from "react";
import {Container} from "react-bootstrap";
import {IMAGES} from "../constants/images";
import {agricultural, special, truck} from "../constants";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import Fancybox from "../hooks/Fancybox";
import {Helmet} from "react-helmet";
import PageTransition from "../components/PageTransition";

const TechnologiesPage = (props) => {

  const [agriculturalLink, setAgriculturalLink] = useState(1);
  const [speciallLink, setSpeciallLink] = useState(1);
  const [truckLink, setTruckLink] = useState(1);

  return (
    <PageTransition>
      <section className="cart-page technologies-page">
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{props.title}</title>
        </Helmet>
        <Container fluid className="product-page-banner">
          <img
            src={IMAGES.TechnologiesBg}
            className="main-banner__image"
            alt="технологии"
          />
          <Container className=" product-page-banner__container">
            <div className="main-banner__title-block product-page-banner__title-block">
              <h1 className="main-banner__title product-page-banner__title">Технологии</h1>
            </div>
          </Container>
        </Container>
        <Breadcrumbs/>

        <Container fluid className="technologies-area">

          <div className="technologies-area__title-block">
            <Container className="technologies-area__container">
              <h2 className="technologies-area__title">
                сельскохозяйственные шины
              </h2>
            </Container>
          </div>

          <nav className="technologies-nav-area">
            <ul className="technologies-nav">

              {agricultural.map(item => {
                return (
                  <li
                    key={item.id}
                    onClick={() => setAgriculturalLink(item.id)}
                    className={"technologies-nav__item " + (agriculturalLink === item.id ? "technologies-nav__item--active" : "")}>
                  <span className="technologies-nav__link">
                    {item.title}
                  </span>
                  </li>
                );
              })
              }

            </ul>
          </nav>

          <div className="technologies-info-slide">
            {agricultural.map(item => {
              return (
                <div
                  key={item.id}
                  className={"technologies-info " + (item.id === agriculturalLink ? "technologies-info--active" : "")}>
                  <div className="technologies-info__image-block">
                    <Fancybox options={{infinite: false}}>
                      <img src={item.image} alt=""
                           data-fancybox="gallery"
                           data-src={item.image}
                           className="technologies-info__image"/>
                    </Fancybox>
                  </div>

                  <Container>
                    <div className="technologies-info__info">
                      <h6 className="technologies-info__title">
                        {item.title}
                      </h6>
                      <p className="technologies-info__desc">
                        {item.text}
                      </p>
                    </div>
                  </Container>
                </div>
              );
            })}
          </div>


        </Container>

        <Container fluid className="technologies-area">

          <div className="technologies-area__title-block">
            <Container className="technologies-area__container">
              <h2 className="technologies-area__title">
                спецтехника
              </h2>
            </Container>
          </div>

          <nav className="technologies-nav-area">
            <ul className="technologies-nav">

              {special.map(item => {
                return (
                  <li
                    key={item.id}
                    onClick={() => setSpeciallLink(item.id)}
                    className={"technologies-nav__item " + (speciallLink === item.id ? "technologies-nav__item--active" : "")}>
                  <span className="technologies-nav__link">
                    {item.title}
                  </span>
                  </li>
                );
              })
              }

            </ul>
          </nav>

          <div className="technologies-info-slide">
            {special.map(item => {
              return (
                <div
                  key={item.id}
                  className={"technologies-info " + (item.id === speciallLink ? "technologies-info--active" : "")}>
                  <div className="technologies-info__image-block">
                    <Fancybox options={{infinite: false}}>
                      <img src={item.image} alt=""
                           data-fancybox="gallery"
                           data-src={item.image}
                           className="technologies-info__image"/>
                    </Fancybox>
                  </div>

                  <Container>
                    <div className="technologies-info__info">
                      <h6 className="technologies-info__title">
                        {item.title}
                      </h6>
                      <p className="technologies-info__desc">
                        {item.text}
                      </p>
                    </div>
                  </Container>
                </div>
              );
            })}
          </div>


        </Container>

        <Container fluid className="technologies-area">

          <div className="technologies-area__title-block">
            <Container className="technologies-area__container">
              <h2 className="technologies-area__title">
                грузовые шины
              </h2>
            </Container>
          </div>

          <nav className="technologies-nav-area">
            <ul className="technologies-nav">

              {truck.map(item => {
                return (
                  <li
                    key={item.id}
                    onClick={() => setTruckLink(item.id)}
                    className={"technologies-nav__item " + (truckLink === item.id ? "technologies-nav__item--active" : "")}>
                  <span className="technologies-nav__link">
                    {item.title}
                  </span>
                  </li>
                );
              })
              }

            </ul>
          </nav>

          <div className="technologies-info-slide">
            {truck.map(item => {
              return (
                <div
                  key={item.id}
                  className={"technologies-info " + (item.id === truckLink ? "technologies-info--active" : "")}>
                  <div className="technologies-info__image-block">
                    <Fancybox options={{infinite: false}}>
                      <img src={item.image} alt=""
                           data-fancybox="gallery"
                           data-src={item.image}
                           className="technologies-info__image"/>
                    </Fancybox>
                  </div>

                  <Container>
                    <div className="technologies-info__info">
                      <h6 className="technologies-info__title">
                        {item.title}
                      </h6>
                      <p className="technologies-info__desc">
                        {item.text}
                      </p>
                    </div>
                  </Container>
                </div>
              );
            })}
          </div>


        </Container>

      </section>
    </PageTransition>
  );
};

export default TechnologiesPage;
