import React from 'react';
import {toggleModalDelete} from "../../redux/actions/modals";
import {useDispatch, useSelector} from "react-redux";
import {removeProductCart} from "../../redux/actions/cart";
import {reloadProductAfterRemoveCart} from "../../redux/actions/products";

const ModalDelete = () => {

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);

  const handleDeleteProductCart = () => {
    dispatch(reloadProductAfterRemoveCart(cart.delete_product))
    dispatch(removeProductCart(cart.delete_product))
    dispatch(toggleModalDelete(false))
  }

  return (
    <div className="thanks-modal">
      <div className="thanks-modal__header">
        <span className="thanks-modal__title">Удалить товар с корзины?</span>
      </div>
      <div className="thanks-modal__footer">
        <div className="thanks-modal__buttons">
          <button
            onClick={() => dispatch(toggleModalDelete(false))}
            className="button button--gray product-card__button-blue">
            <span>Нет</span>
          </button>
          <button
            onClick={() => handleDeleteProductCart()}
            className="button button--red product-card__button-blue">
            <span>Да</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalDelete;
