import React from 'react';
import {IMAGES} from "../../constants/images";
import {Container} from "react-bootstrap";

const AboutGrid = () => {
  return (
    <div className="about-grid">
      <div className="about-grid__block">
        <img src={IMAGES.About1} alt="" className="about-grid__image"/>
      </div>
      <div className="about-grid__block">
        <h6 className="about-grid__title">
          {">1000"}
        </h6>
        <span className="about-grid__info">единиц</span>
        <span className="about-grid__desc">Ассортимент шин</span>
      </div>
      <div className="about-grid__block">
        <img src={IMAGES.About2} alt="" className="about-grid__image"/>
      </div>
      <div className="about-grid__block">
        <h6 className="about-grid__title">
          {">100"}
        </h6>
        <span className="about-grid__info">стран мира</span>
        <span className="about-grid__desc">Импортируют шины для разных категорий</span>
      </div>
      <div className="about-grid__block">
        <img src={IMAGES.About3} alt="" className="about-grid__image"/>
      </div>
      <div className="about-grid__block">
        <h6 className="about-grid__title">
          {">250"}
        </h6>
        <span className="about-grid__info">сотрудников</span>
        <span className="about-grid__desc">Профессионалы в своей области </span>
      </div>
      <div className="about-grid__block">
        <img src={IMAGES.About4} alt="" className="about-grid__image"/>
      </div>
      <div className="about-grid__block">
        <h6 className="about-grid__title">
          {">250"}
        </h6>
        <span className="about-grid__info">тыс. м²</span>
        <span className="about-grid__desc">Занимаемая площадь</span>
      </div>
      <div className="about-grid__block">
        <img src={IMAGES.About5} alt="" className="about-grid__image"/>
      </div>
    </div>
  );
};

export default AboutGrid;
