export const LINKS = [
  {
    name: 'Каталог',
    link: '/catalog',
    sublink: [
      {
        name: 'все шины',
        link: '/catalog',
        matchSection: 0,
      },
      {
        name: 'для сельхозтехники',
        link: '/catalog?sections=14',
        matchSection: 14,
      },
      {
        name: 'для спецтехники',
        link: '/catalog?sections=27',
        matchSection: 27,
      },
      {
        name: 'для грузовой техники',
        link: '/catalog?sections=18',
        matchSection: 18,
      }
    ]
  },
  {
    name: 'Модели',
    link: '/models',
    sublink: [
      {
        name: 'все модели',
        link: '/models',
      },
      {
        name: 'для сельхозтехники',
        link: '/models/agricultural ',
      },
      {
        name: 'для спецтехники',
        link: '/models/special',
      },
      {
        name: 'для грузовой техники',
        link: '/models/trucks',
      }
    ]
  },
  {
    name: 'О производителе',
    link: '/about',
    sublink: [
      // {
      //   name: 'Гарантия',
      //   link: '/about/warranty',
      // },
      {
        name: 'О компании',
        link: '/about/about-company',
      },
      {
        name: 'Технологии',
        link: '/about/technologies',
      }
    ]
  },
  {
    name: 'Контакты',
    link: '/contacts',
    sublink: [
      {
        name: 'Контакты',
        link: '/contacts/contacts-company',
      },
      {
        name: 'Стать дилером',
        link: '/contacts/invite',
      },
    ]
  },
  {
    name: 'Доставка и оплата',
    link: '/delivery',
  },
]

export const LINKS_FOOTER = [
  {
    name: 'Шины',
    link: '/catalog'
  },
  {
    name: 'Модели',
    link: '/models'
  },
  {
    name: 'О компании',
    link: '/about/about-company'
  },
  {
    name: 'Соглашение',
    link: '/politika-konfidentsialnosti'
  },
  {
    name: 'Контакты',
    link: '/contacts/contacts-company'
  },
  {
    name: 'Доставка и оплата',
    link: '/delivery'
  }

]
