import React from 'react';
import {Col, Row} from "react-bootstrap";
import Fancybox from "../../hooks/Fancybox";
import {IMAGES} from "../../constants/images";
import {imageUrl} from "../../constants";

const Model = ({name, image, brand, section}) => {

  return (
    <div className="product-block">
      <div className="product model">
        <Row className="product__row m-0 flex-grow-1 flex-lg-row flex-column">
          <Col lg={3} md={12} sm={12} className="product__col">
            <Fancybox options={{infinite: false}}>
              <div className="product__image-block"
                   data-fancybox="gallery"
                   data-src={`${imageUrl}${image}`}>
                <img src={`${imageUrl}${image}`} className="product-image" alt="" width="165" height="250"/>
                <img src={IMAGES.Zoom} alt="" className="zoom"/>
              </div>
            </Fancybox>
          </Col>
          <Col lg={9} md={12} sm={12} className="product__col">
            <div className="product__info-block product-info">
              <h4 className="product-info__title">
                {brand} {name}
              </h4>
              <div className="product-info__params">
                <p className="name-params">
                  <span>Производитель </span>
                </p>
                <p className="props-params">
                  <strong>{brand}</strong>
                </p>
              </div>
              <div className="product-info__params">
                <p className="name-params">
                  <span>Название модели </span>
                </p>
                <p className="props-params">
                  <span>{name}</span>
                </p>
              </div>
              <div className="product-info__params">
                <p className="name-params">
                  <span>Тип ТС</span>
                </p>
                <p className="props-params">
                  <span>{section}</span>
                </p>
              </div>
            </div>
          </Col>

        </Row>
      </div>
    </div>
  );


};

export default Model;
