import {SET_MODELS,SET_MODEL,RESET_MODELS} from '../constants'

const initialState = {
  models: [],
  loadingModels: true,
  pagination: {},
  model: {},
  loadingModel: true,
}

const modelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODELS:
      return {
        ...state,
        models: action.payload.models,
        pagination: action.payload.paginate,
        loadingModels: false
      }
    case SET_MODEL:
      return {
        ...state,
        model: action.payload,
        loadingModel: false
      }
    case RESET_MODELS:
      return initialState
    default:
      return state;
  }
}

export default modelsReducer;
