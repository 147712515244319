import {
  DELETE_PRODUCT,
  GET_PRODUCT_CART,
  OPEN_MODAL_CART,
  REMOVE_ALL_PRODUCT_CART,
  REMOVE_PRODUCT_UNIT_CART,
  RESET_CART,
  RESET_MODAL_CART,
  SELECT_ALL_PRODUCT,
  SET_PRODUCT_CART,
  TOGGLE_DROP_WINDOW_CART,
  SELECT_PRODUCT_UNIT_CART,
  UNSELECT_PRODUCT_CART,
  SET_DELETE_PRODUCT
} from '../constants'
import {imageUrl} from "../../constants";

const initialState = {
  products: [],
  total_price: 0,
  total_count: 0,
  last_add_product_cart: {},
  delete_product: {},
  modal: false,
  select_all: false,
  drop_window_cart: false
}

const cartReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_PRODUCT_CART: {
      // console.log('action.payload', action.payload)
      let _products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : []
      let _total_price = localStorage.getItem('total_price') ? JSON.parse(localStorage.getItem('total_price')) : 0
      let _total_count = 0
      const _product = action.payload
      let _last_add_product_cart = action.payload

      const exist = _products.find((item) => item.id === _product.id)

      if (exist) {
        const newProducts = [..._products]
        _products.forEach((item, index) => {
          if (item.id === exist.id) {
            newProducts[index].count = newProducts[index].count + 1
            _last_add_product_cart = item
          }
        })
      } else {
        _products = [..._products, {..._product, count: 1, url: `${imageUrl}${_product.model.picture}`}]
      }
      _products.filter(item => {
        _total_count += item.count
      })
      // if (_last_add_product_cart.id) {
      //   if (_last_add_product_cart.count === 1) _last_add_product_cart.count = 0
      //   else _last_add_product_cart.count += 1
      // }
      _total_price += action.payload.new_price ? action.payload.new_price : action.payload.price
      localStorage.setItem('total_price', JSON.stringify(_total_price))
      localStorage.setItem('products', JSON.stringify(_products))

      return {
        ...state,
        products: _products,
        last_add_product_cart: _last_add_product_cart,
        total_count: _total_count,
        total_price: _total_price
      }
    }

    case REMOVE_PRODUCT_UNIT_CART: {
      let _total_count = 0
      let _products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : []
      let _total_price = localStorage.getItem('total_price') ? JSON.parse(localStorage.getItem('total_price')) : 0
      let _last_add_product_cart = action.payload

      _products.forEach((item, index) => {
        if (item.id === action.payload.id) {
          if (_products[index].count > 1) {
            _products[index].count = _products[index].count - 1
            _last_add_product_cart = item
          }
        }
      })
      _products.filter(item => {
        _total_count += item.count
        item.select = false
      })
      _total_price -= action.payload.new_price ? action.payload.new_price : action.payload.price
      localStorage.setItem('total_price', JSON.stringify(_total_price))
      localStorage.setItem('products', JSON.stringify(_products))
      return {
        ...state,
        products: _products,
        last_add_product_cart:_last_add_product_cart,
        total_count: _total_count,
        total_price: _total_price
      }
    }

    case SELECT_ALL_PRODUCT: {
      const _products = state.products
      let _select_all = state.select_all
      _select_all = action.payload
      _products.forEach((item, index) => {
        _products[index].select = _select_all
      })
      localStorage.setItem('products', JSON.stringify(_products))
      return {
        ...state,
        select_all: _select_all,
        products: _products
      }
    }
    case SET_DELETE_PRODUCT: {
      return {
        ...state,
        delete_product: action.payload
      }
    }
    case DELETE_PRODUCT: {
      let _total_count = 0
      let _products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : []
      let _total_price = 0
      const _new_products = _products.filter(item => item.id !== action.payload.id)
      // _total_price -= action.payload.new_price ? action.payload.new_price : action.payload.price

      _new_products.filter(item => {
        _total_count += item.count
        _total_price += (item.count ? item.count : 1) * (item.new_price ? item.new_price : item.price)
      })
      if (_new_products.length === 0) {
        _total_price = 0
        localStorage.removeItem('making_order')
      }
      localStorage.setItem('products', JSON.stringify(_new_products))
      localStorage.setItem('total_price', JSON.stringify(_total_price))
      return {
        ...state,
        delete_product: {},
        products: _new_products,
        total_price: _total_price,
        total_count: _total_count
      }
    }

    case REMOVE_ALL_PRODUCT_CART: {
      let _products = state.products
      _products = []
      localStorage.removeItem('total_price')
      localStorage.removeItem('products')
      localStorage.removeItem('making_order')
      return {
        ...state,
        products: _products,
        total_count: 0,
        total_price: 0
      }
    }

    case OPEN_MODAL_CART: {
      state.modal = !state.modal
      return {...state}
    }

    case GET_PRODUCT_CART: {
      let _total_price = 0
      let _count_select = 0
      let _products = []
      let _select_all = state.select_all
      if (JSON.parse(localStorage.getItem('total_price'))) {
        _total_price = JSON.parse(localStorage.getItem('total_price'))
      }
      if (JSON.parse(localStorage.getItem('products'))) {
        _products = JSON.parse(localStorage.getItem('products'))
        _products.filter(item => {
          state.total_count += item.count
          if (item.select) {
            _count_select += 1
          }
        })
      }
      _select_all = _count_select === _products.length;
      return {
        ...state,
        products: _products,
        total_price: _total_price,
        select_all: _select_all
      }
    }

    case RESET_MODAL_CART: {

      return {
        ...state,
        modal: false
      }
    }

    case SELECT_PRODUCT_UNIT_CART: {
      let _products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : []
      let _select_all = state.select_all
      let _count_select = 0
      _products.forEach(item => {
        if (item.id === action.payload.id) {
          item.select = item.select ? !item.select : true
        }
        if (item.select) {
          _count_select += 1
        }
      })

      _select_all = _count_select === _products.length;

      localStorage.setItem('products', JSON.stringify(_products))
      return {
        ...state,
        products: _products,
        select_all: _select_all
      }
    }

    case TOGGLE_DROP_WINDOW_CART: {
      return {
        ...state,
        drop_window_cart: action.payload
      }
    }

    case UNSELECT_PRODUCT_CART: {
      let _products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : []
      _products.forEach(item => {
        item.select = false
      })

      localStorage.setItem('products', JSON.stringify(_products))
      return {
        ...state,
        select_all: false,
        products: _products
      }
    }

    case RESET_CART: {
      localStorage.removeItem('products')
      localStorage.removeItem('total_price')
      return initialState
    }

    default:
      return state;
  }
}

export default cartReducer;
