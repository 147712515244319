import {Link} from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";
import "./footer.sass";
import {MAIL, PHONE_LINK, PHONE_LINK2} from "../../constants";
import {LINKS_FOOTER} from "../../constants/links";
import {IMAGES} from "../../constants/images";
import {toggleModalCall} from "../../redux/actions/modals";
import {useDispatch} from "react-redux";

const Footer = () => {
  const dispatch = useDispatch();

  return (
    <Container fluid className="footer-area px-0">
      <Container className="footer">
        <ul className="footer__navigation footer-navigation d-md-flex d-none ">
          {LINKS_FOOTER.map((item, index) => {
            return (
              <li className="footer-navigation__item" key={index}>
                <Link to={item.link} className="footer-navigation__link">
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>

        <p className="footer__info">
          Официальный дистрибьютор Petlas и Starmaxx в России
        </p>

        <Row>
          <Col
            lg="4"
            md="12"
            className="d-sm-flex d-none justify-content-lg-start justify-content-md-start ">
            <div className="footer-block">
              <div
                className="footer-block__fix"
                style={{marginLeft: "inherit"}}
              >
                <div className="footer-block__location">
                  <img
                    src={IMAGES.Point}
                    alt="Россия"
                    className="footer-block__icon"
                  />
                  <p>Россия</p>
                </div>

                <div className="footer-block__contact">
                  <img
                    src={IMAGES.Mail}
                    alt="Почта"
                    className="footer-block__icon"
                  />
                  <a href={`mailto:${MAIL}`}>{MAIL}</a>
                </div>

                {/*<div className="footer-block__contact">*/}
                {/*  <img*/}
                {/*    src={IMAGES.Mail}*/}
                {/*    alt="Почта"*/}
                {/*    className="footer-block__icon"*/}
                {/*  />*/}
                {/*  <a href={`mailto:${MAIL2}`}>{MAIL2}</a>*/}
                {/*</div>*/}

                <div className="footer-block__contact">
                  <img
                    src={IMAGES.Phone}
                    alt="телефон"
                    className="footer-block__icon"
                  />
                  <a href={`tel:${PHONE_LINK}`}>
                    +7 495 <strong>740-91-00</strong>
                  </a>
                </div>
              </div>
              <Link to="/politika-konfidentsialnosti" className="footer-block__text">
                Политика конфиденциальности
              </Link>
            </div>
          </Col>

          <Col
            lg="4"
            md="12"
            className="justify-content-lg-center justify-content-md-start"
          >
            <div className="footer-block footer-block__middle">
              <Link to="/" className="footer-block__logo">
                <img src={IMAGES.Logo} alt="starmaxx"/>
              </Link>
              <a href={`tel:${PHONE_LINK}`} className="footer-block__phone">
                +7 495 <strong>740-91-00</strong>
              </a>
              <a
                href="#header"
                className="button button--white footer-block__button-white"
                onClick={() => dispatch({type: 'TOGGLE_MODAL_CALL_MOBILE', payload: true})}
              >
                <span>Заказать звонок</span>
              </a>
            </div>
          </Col>

          <Col
            lg="4"
            md="12"
            className="d-flex justify-content-lg-end justify-content-md-start"
          >
            <div className="footer-block">
              <div className="footer-block__fix d-sm-block d-none">
                <div className="footer-block__location ">
                  <img
                    src={IMAGES.Point}
                    alt="Россия"
                    className="footer-block__icon"
                  />
                  <p>Турция</p>
                </div>

                <div className="footer-block__contact">
                  <img
                    src={IMAGES.Mail}
                    alt="Почта"
                    className="footer-block__icon"
                  />
                  <a href={`mailto:${MAIL}`}>{MAIL}</a>
                </div>

                {/*<div className="footer-block__contact">*/}
                {/*  <img*/}
                {/*    src={IMAGES.Mail}*/}
                {/*    alt="Почта"*/}
                {/*    className="footer-block__icon"*/}
                {/*  />*/}
                {/*  <a href={`mailto:${MAIL}`}>{MAIL}</a>*/}
                {/*</div>*/}

                <div className="footer-block__contact">
                  <img
                    src={IMAGES.Phone}
                    alt="телефон"
                    className="footer-block__icon"
                  />
                  <a href={`tel:${PHONE_LINK2}`}>
                    90 312 <strong>309 30 30</strong>
                  </a>
                </div>
              </div>

              <div className="footer-block__text">
                Petlas/Starmaxx © {new Date().getFullYear()} г.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="footer-line">
        <Container className="footer-line__container">
          <p className="footer-line__text">
            {new Date().getFullYear()} © "Starmaxx-tyres.ru" — автомобильные шины (зимние и
            летние шины). Сайт носит исключительно информационный характер и
            никакая информация, опубликованная на нём, ни при каких условиях не
            является публичной офертой, определяемой положениями пункта 2 статьи
            437 Гражданского кодекса Российской Федерации. Информация,
            фотографии и характеристики товара могут отличаться от фактических
            параметров. Уточняйте информацию у менеджеров и внимательно
            проверяйте все характеристики при получении товара.
          </p>
        </Container>
      </div>
    </Container>
  );
};

export default Footer;
