import React from "react";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import {Helmet} from "react-helmet";
import PageTransition from "../components/PageTransition";

const PoliticsPage = (props) => {
  return (
    <PageTransition>
      <section className="container-fluid">
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{props.title}</title>
        </Helmet>
        <Breadcrumbs/>
        <div className="container container-politics">
          <h1>
            Политика конфиденциальности
          </h1>
          <p>
          <span>
            Ваша конфиденциальность очень важна для нас.
          </span>
            Настоящее Пользовательское соглашение является публичным документом администратора сайта (далее –
            Администратор) и определяет порядок использования посетителями (далее - Посетитель) сайта, принадлежащего
            Администратору, и обработки, хранения и иного использования информации, получаемой Администратором от
            Посетителя на сайте Администратора. Администратор сайта может изменить в любой момент данное
            Пользовательское
            соглашение без уведомления п осетителя сайта.
          </p>
          <h2>
            Предоставление информации клиентом
          </h2>
          <p>
            При оформлении заказа Клиент может предоставить следующую информацию:

            — Фамилия, Имя, адрес для доставки заказов, номер контактного телефона.

            Предоставляя свои персональные данные, Клиент соглашается на их обработку в течение неопределенного срока
            интернет-магазином (далее также «Продавец») в целях исполнения Продавцом своих обязательств перед клиентом,
            продажи им товаров и предоставления услуг, предоставления им справочной информации, а также в целях
            продвижения товаров, работ и услуг, а также соглашается на получение электронных рассылок.

            При обработке персональных данных Клиента интернет–магазин руководствуется Федеральным законом «О
            персональных
            данных», Федеральным законом «О рекламе» и локальными нормативными документами.
          </p>
          <h2>
            Использование информации, предоставленной Клиентом и получаемой Продавцом
          </h2>
          <p>
            Продавец использует предоставленные Клиентом данные в течение всего срока регистрации Клиента на Сайте в
            целях:

            -Для обработки заказов Клиента и выполнения своих обязательств перед Клиентом;

            -Для осуществления деятельности по продвижению товаров и услуг.
          </p>
          <h2>
            Предоставление и передача информации, полученной Продавцом
          </h2>
          <p>
            Продавец обязуется не передавать полученную от Клиента информацию третьим лицам за исключением случаев
            технического взаимодействия с внешними сервисами для проведения е-мейл, смс и других типов уведомлений и
            рассылок.

            Не считается нарушением обязательств передача информации в соответствии с обоснованными и применимыми
            требованиями законодательства Российской Федерации. Продавец вправе использовать технологию "cookies".
            "Cookies" не содержат конфиденциальную информацию и не передаются третьим лицам. Продавец получает
            информацию
            об ip-адресе посетителя сайта и сведения о том, по ссылке с какого интернет-сайта посетитель пришел. Данная
            информация не используется для установления личности посетителя.

            Продавец при обработке персональных данных принимает необходимые и достаточные организационные и технические
            меры для защиты.
          </p>
        </div>
      </section>
    </PageTransition>
  );
};

export default PoliticsPage;
