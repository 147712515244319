import React, {useEffect, useState} from 'react';
import api from '../api/index'
import {Container} from "react-bootstrap";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import {IMAGES} from "../constants/images";
import PaymentMethod from "../components/cart/paymentMethod";
import CartProductList from "../components/cart/cartProductList";
import MakingOrder from "../components/cart/makingOrder";
import DeliveryProduct from "../components/cart/deliveryProduct";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {Declination, Price} from "../utils/helpers";
import {resetCart, unselectProductCart} from "../redux/actions/cart";
import {makingOrder, orderAccess, resetErrors, resetMakingOrder, setErrors} from "../redux/actions/making_order";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import PageTransition from "../components/PageTransition";

const CartPage = (props) => {

  const cart = useSelector((state: RootState) => state.cart);
  const making_order = useSelector((state: RootState) => state.making_order);

  const dispatch = useDispatch()

  const [submiting, setSubmiting] = useState(false)

  const [errorServer, setErrorServer] = useState('')

  useEffect(() => {
    return () => {
      dispatch(resetMakingOrder())
      dispatch(unselectProductCart())
    }
  }, []);

  const handleMakingOrder = () => {
    dispatch(makingOrder())
  }

  const [error, setError] = useState('')

  const handleSubmit = (e: any) => {
    setError('')
    setErrorServer('')
    setSubmiting(true)
    e.preventDefault();
    e.stopPropagation()
    dispatch(resetErrors())
    // console.log('Сработал submit')
    const data = {
      products: cart.products,
      total_price: cart.total_price,
      total_count: cart.total_count,
      // payment_method: {name: making_order.payment_method.name},
      fio: making_order.fio,
      phone: making_order.phone,
      email: making_order.email,
      organization: making_order.organization,
      legal_address: making_order.legal_address,
      comment: making_order.comment,
      inn: making_order.inn,
      delivery: making_order.delivery,
      delivery_comment: making_order.delivery_comment,

    }
    if (data.fio === '' || data.phone === '' || making_order.agreement === false) {
      setSubmiting(false)
      if (data.fio === '') {
        // console.log(1)
        setError('fio')
        dispatch(setErrors('fio', 'Введите имя'))
      }
      if (data.phone === '') {
        // console.log(2)
        setError('phone')
        dispatch(setErrors('phone', 'Введите номер телефона'))
      }
      if (making_order.agreement === false) {
        // console.log(3)
        setError('agreement')
        dispatch(setErrors('agreement', 'Необходимо согласиться с политикой конфиденциальности'))
      }
      return
    }

    api.setOrder(data).then(res => {
      // console.log('res', res)
      dispatch(resetMakingOrder())
      dispatch(resetCart())
      dispatch(orderAccess(true))
    }).catch(e => {
      console.log('e', e.message)
      setErrorServer(e.message)
    }).finally(()=>{
      setSubmiting(false)
    })
  }

  return (

    <section className="cart-page">
        <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
      </Helmet>
      <Container fluid className="cart-banner">

        <img src={IMAGES.CartBanner1}
             className="cart-banner__image"
             alt="корзина"/>

        <Container className="cart-banner__container">

          <div className="cart-banner__title-block">
            <h1 className="about-company-page-banner__title cart-banner__title">
              корзина
            </h1>
          </div>

        </Container>

      </Container>

      <Breadcrumbs/>

      {making_order.order_access
        ?
        <Container fluid className="cart-area">
          <Container className="cart-area__container">
            <div className="cart-block">
              <div className="cart-block__title-block">
                <h2 className="cart-block__title">
                  в корзине
                </h2>
                <div className="count-product">
                <span className="count-product__count">
                  0
                  {" "}
                </span>
                  {Declination(0, ['товар', 'товара', 'товаров'])}
                </div>
              </div>
              <div className="cart-stub">
                <div className="cart-stub__content">
                  <p className="cart-stub__text">
                    <span className="blue"><strong>Ваш заказ{" "}</strong>успешно принят.</span> После обработки заявки
                    менеджером вам обязательно перезвонят.
                  </p>
                  <div className="cart-stub__buttons d-flex justify-content-center pb-0">
                    <Link to="/catalog" className="button button--red cart-stub__button">
                        <span>
                         найти еще товары
                        </span>
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </Container>
        </Container>
        :
        <form className="cart-wrapper-bloсks cart-area-bg"
              onSubmit={(e) => handleSubmit(e)}>

          <CartProductList/>

          {cart.products.length !== 0
          &&
          <>
            <Container fluid className="cart-area ">
              <Container className="cart-area__container">
                <div className="cart-block cart-block-total-price cart-order">
                  <div className="cart-total-price-area">
                    <div className="total-price">
                <span className="total-price__text">
                  итого:{" "}
                </span>
                      <span className="total-price__price">
                    {Price(cart.total_price)}
                </span>
                      <span className="total-price__rub">{" "}
                        ₽
                </span>
                    </div>
                  </div>
                  {!making_order.making
                  &&
                  <button
                    onClick={() => handleMakingOrder()}
                    className="button  button--red cart-block__button-blue">
                    <span>
                     перейти к оформлению
                    </span>
                  </button>
                  }

                </div>
              </Container>
            </Container>
          </>
          }


          {cart.products.length !== 0 && making_order.making
          &&
          <>
            {/*<PaymentMethod/>*/}

            <MakingOrder
              setError={setError}
              error={error}/>

            <DeliveryProduct/>

            <Container fluid className="cart-area">
              <Container className="cart-area__container">
                <div className="cart-block cart-block-total-price">
                  <div className="cart-total-price-area">
                    <div className="total-price">
                      <span className="total-price__text">
                        всего:{" "}
                      </span>
                      <span className="total-price__price">
                        {Price(cart.total_price)}
                      </span>
                      <span className="total-price__rub">{" "}
                        ₽
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    {submiting
                      ?
                      <button
                        disabled
                        type="button"
                        className="button  button--blue cart-block__button-blue">
                    <span>
                      Отправка заказа
                    </span>
                      </button>
                      :
                      <button
                        type="submit"
                        className="button  button--blue cart-block__button-blue">
                    <span>
                      оформить покупку
                    </span>
                      </button>
                    }
                    {errorServer && (
                    <div className="modal-order-call__form-error">
                      {errorServer}
                    </div>
                    )}
                  </div>


                </div>
              </Container>
            </Container>
          </>
          }
        </form>
      }
    </section>

  );
};

export default CartPage;
