import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import api from "../api/index";
import {Col, Container, Row} from "react-bootstrap";
import {IMAGES} from "../constants/images";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import {Link} from "react-router-dom";
import SliderProduct from "../components/slider-models/SliderProduct";
import Fancybox from "../hooks/Fancybox";
import {IProduct} from "../types/types";
import {openCart, setProductCart} from "../redux/actions/cart";
import {useDispatch, useSelector} from "react-redux";
import {setProduct, resetProducts, reloadProductAfterAddCart} from "../redux/actions/products";
import {RootState} from "../redux/store";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {colorSkeleton, imageUrl, sections} from "../constants";
import {Price} from "../utils/helpers";
import {Helmet} from "react-helmet";
import ModelBanner from "../components/model-banner/ModelBanner";
import PageTransition from "../components/PageTransition";

const ProductPage = (props) => {

  const {product_id} = useParams();

  const products = useSelector((state) => state.products);

  const {product} = products;

  const dispatch = useDispatch();


  const init = () => {
    api.getProduct(product_id).then(res => {
      dispatch(setProduct(res));
    });
  };

  useEffect(() => {
    init();
    return () => {
      dispatch(resetProducts());
    };
  }, [product_id]);

  const generationSection = (id) => {
    let section = {};
    if (id === 14) {
      section = sections["agricultural"];
    }
    if (id === 18) {
      section = sections["trucks"];
    }
    if (id === 27) {
      section = sections["special"];
    }
    return section;
  };

  const handleAddProductCart = (data) => {
    dispatch(reloadProductAfterAddCart(data));
    dispatch(setProductCart(data));
    dispatch(openCart());
  };
  // console.log(product)
  return (
    
    <section className="cart-page">
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{product?.name}</title>
      </Helmet>

      {products.loadingProduct
        ?
        <SkeletonTheme
          baseColor={colorSkeleton["baseColor"]}
          highlightColor={colorSkeleton["highlightColor"]}>
          <Skeleton count={1} height={190} style={{width: "100%"}}/>
        </SkeletonTheme>
        :
        <ModelBanner
          section={generationSection(product.section_id)}
        />
      }

      {/*<Container fluid className="product-page-banner">*/}
      {/*  <img*/}
      {/*    src={IMAGES.ProductPageBg1}*/}
      {/*    className="main-banner__image"*/}
      {/*    alt="Для сельхозтехники"*/}
      {/*  />*/}
      {/*  <Container  className=" product-page-banner__container">*/}
      {/*    <div className="main-banner__title-block product-page-banner__title-block">*/}
      {/*      <h1 className="main-banner__title product-page-banner__title">Шины для сельхозтехники </h1>*/}
      {/*    </div>*/}
      {/*  </Container>*/}
      {/*</Container>*/}

      <Breadcrumbs/>
      <Container fluid className="cart-area cart-area-bg">
        <Container className="cart-area__container">

          {products.loadingProduct
            ?
            <SkeletonTheme
              baseColor={colorSkeleton["baseColor"]}
              highlightColor={colorSkeleton["highlightColor"]}>
              <Skeleton count={1} height={400}/>
            </SkeletonTheme>
            :
            <div className="cart-block">
              <div className="product-block">
                <h2 className="product-block__title">
                  {product?.name}
                </h2>
                <div className="product">
                  <Row className="product__row m-0 flex-grow-1 flex-lg-row flex-column">
                    <Col lg={3} md={12} sm={12} className="product__col">
                      <Fancybox options={{infinite: false}}>
                        <div className="product__image-block"
                             data-fancybox="gallery"
                             data-src={`${imageUrl}${product?.model?.picture}`}>
                          <img src={`${imageUrl}${product?.model?.picture}`}
                               className="product-image"
                               alt=""
                               width="165"
                               height="250"
                          />
                          <img src={IMAGES.Zoom} alt="" className="zoom"/>
                        </div>
                      </Fancybox>
                    </Col>
                    <Col lg={5} md={12} sm={12} className="product__col">
                      <div className="product__info-block product-info">
                        <h4 className="product-info__title">
                          Все параметры
                        </h4>
                        <div className="product-info__params">
                          <p className="name-params">
                            <span>Артикул</span>
                          </p>
                          <p className="props-params">
                            <span>{product?.artnumber}</span>
                          </p>
                        </div>

                        <div className="product-info__params">
                          <p className="name-params">
                            <span>Производитель </span>
                          </p>
                          <p className="props-params">
                            <strong>{product?.brand?.name}</strong>
                          </p>
                        </div>

                        <div className="product-info__params">
                          <p className="name-params">
                            <span>Название модели</span>
                          </p>
                          <p className="props-params">
                            <span>{product?.model_name}</span>
                          </p>
                        </div>

                        <div className="product-info__params">
                          <p className="name-params">
                            <span>Размер</span>
                          </p>
                          <p className="props-params">
                            <span>{product?.index_size}</span>
                          </p>
                        </div>

                        <div className="product-info__params">
                          <p className="name-params">
                            <span>Норма слойности</span>
                          </p>
                          <p className="props-params">
                            <span>{product?.pr}</span>
                          </p>
                        </div>

                        <div className="product-info__params">
                          <p className="name-params">
                            <span>Тип</span>
                          </p>
                          <p className="props-params">
                            <span>{product?.type}</span>
                          </p>
                        </div>

                        <div className="product-info__params">
                          <p className="name-params">
                            <span>Комплектация</span>
                          </p>
                          <p className="props-params">
                            <span>{product?.options}</span>
                          </p>
                        </div>

                        <div className="product-info__params">
                          <p className="name-params">
                            <span>Индекс нагрузки (скорости)</span>
                          </p>
                          <p className="props-params">
                            <span>{product?.indexes}</span>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4} md={12} sm={12} className="product__col">
                      <div className="product__cart-block product-info-card">
                        <h4 className="product-info__title">Добавить в корзину</h4>

                        <div className="product-info__params product-info-card__params">
                          <p className="name-params">
                            <span>Цена</span>
                          </p>
                          <p className="props-params">
                            <span className="props-params-price">
                              {Price(product.new_price ? product.new_price : product.price)}
                              <b> ₽</b></span>
                          </p>
                        </div>

                        {/*<div className="product-info__params product-info-card__params align-items-center">*/}

                        {/*<p className="name-params">*/}
                        {/*  <span>Количество</span>*/}
                        {/*</p>*/}

                        {/*<div className="props-params props-params-count">*/}
                        {/*  <div className="props-params-count__area">*/}
                        {/*    <div className="count">*/}
                        {/*      <button className="button">*/}
                        {/*        &ndash;*/}
                        {/*      </button>*/}
                        {/*      <input type="text"*/}
                        {/*             value={product?.count ? product.count : 1}*/}
                        {/*             className="form-control"*/}
                        {/*             readOnly/>*/}
                        {/*      <button*/}
                        {/*        type="button"*/}
                        {/*        className="button"*/}
                        {/*        // onClick={() => handleAddProductCart(product)}*/}
                        {/*      >*/}
                        {/*        +*/}
                        {/*      </button>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        {/*</div>*/}

                        {/*<div className="product-info__params product-info-card__params">*/}
                        {/*  <p className="name-params">*/}
                        {/*    <span>Общая цена</span>*/}
                        {/*  </p>*/}
                        {/*  <p className="props-params">*/}
                        {/*    <span className="props-params-price props-params-price--red">*/}
                        {/*      {totalPriceProduct()}*/}
                        {/*      <b> ₽</b></span>*/}
                        {/*  </p>*/}
                        {/*</div>*/}


                        <div className="product-info-card__buttons">
                          {product?.count
                            ?
                            <Link
                              to="/cart"
                              className="button button--red ">
                                <span>
                                 В корзине
                                </span>
                            </Link>
                            :
                            <button
                              onClick={e => handleAddProductCart(product)}
                              className="button button--gray ">
                                <span>
                                  Добавить в  корзину
                                </span>
                            </button>
                          }


                          {/*<button className="button button--blue ">*/}
                          {/*<span>*/}
                          {/*Выписать счет*/}
                          {/*</span>*/}
                          {/*</button>*/}
                        </div>

                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          }
        </Container>
      </Container>


      <Container fluid className="models-area">
        <Container>
          <div className="title-block">
            <h2 className="title">Популярные товары</h2>
            <Link to="/catalog" className="title-block__link">
              Смотреть все
            </Link>
          </div>
          <div className="slider-product-area">
            <SliderProduct/>
          </div>
        </Container>
      </Container>

    </section>

  );
};

export default ProductPage;
