import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import {useNavigate, useSearchParams, useLocation, Link} from "react-router-dom";
import {useSelector} from "react-redux";
import queryString from "query-string";

const PaginationModels = ({pagination, location}) => {

  const {total, limit, offset} = pagination

  const navigate = useNavigate();

  const goTo = (e) => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
    navigate(`${location.pathname}?page=${+e.selected + 1}&limit=${limit}`)
  }

  return (
    <div className="pagination-area">
      {total
        ?
        <ReactPaginate
          hrefBuilder={(page, pageCount, selected) => {
            return page >= 1 && page <= pageCount ? `${location.pathname}?page=${page}&limit=${limit}` : '#'
          }}
          // onPageActive={(e)=> console.log(e)}
          breakLabel="..."
          nextLabel="Далее"
          previousLabel="Назад"
          containerClassName="pagination mb-0"
          pageClassName="pagination__item"
          pageLinkClassName="pagination__link"
          previousClassName="pagination__button _prev"
          nextClassName="pagination__button _next"
          breakClassName="pagination__dots"
          onPageChange={(e) => goTo(e)}
          forcePage={offset / limit}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={Math.ceil(total / limit)}
          renderOnZeroPageCount={null}
          hrefAllControls
        />
        :
        ''
      }
    </div>
  );
};

export default PaginationModels;
