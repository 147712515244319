import {EffectFade, Pagination, Autoplay} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import "swiper/css/effect-fade";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import {IMAGES} from "../../constants/images";
import {Col, Container, Row} from "react-bootstrap";

const SliderMain = () => {

  return (
    <div className="slider-main">

      <Swiper
        centeredSlides={true}
        effect={"fade"}
        modules={[EffectFade, Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={'auto'}
        loop={true}
        speed={800}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{clickable: true}}
        allowTouchMove={false}>

        <SwiperSlide>
          <Container className="slider-main__container">
            <Row>
              <Col>
                <div className="main-banner__title-block title-bg">
                  <h1 className="main-banner__title">Для сельхозтехники</h1>
                  <p className="main-banner__sub-title">
                    Мощные механизмы не могут обойтись без надёжных и качественных шин
                  </p>
                </div>
              </Col>
            </Row>
          </Container>

          <img
            src={IMAGES.BannerSlide1}
            className="main-banner__image"
            alt="Для сельхозтехники"
          />

        </SwiperSlide>

        <SwiperSlide>
          <Container className="slider-main__container">
            <Row>
              <Col>
                <div className="main-banner__title-block title-bg">
                  <h1 className="main-banner__title">Для грузовой техники</h1>
                  <p className="main-banner__sub-title">
                    Мощные механизмы не могут обойтись без надёжных и качественных шин
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <img
            src={IMAGES.BannerSlide2}
            className="main-banner__image"
            alt="Для грузовой техники"
          />
        </SwiperSlide>

        <SwiperSlide>
          <Container className="slider-main__container">
            <Row>
              <Col>
                <div className="main-banner__title-block title-bg">
                  <h1 className="main-banner__title">Для Спецтехники</h1>
                  <p className="main-banner__sub-title">
                    Мощные механизмы не могут обойтись без надёжных и качественных шин
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <img
            src={IMAGES.BannerSlide3}
            className="main-banner__image"
            alt="Для Спецтехники"
          />
        </SwiperSlide>

      </Swiper>

    </div>
  );
};

export default SliderMain;
