import {
  RESET_PRODUCTS,
  SET_PRODUCTS,
  SET_PRODUCT,
  LOADING_PRODUCTS,
  RELOAD_PRODUCT_AFTER_REMOVE_CARD, RELOAD_PRODUCT_AFTER_ADD_CARD,
  SET_POPULAR_PRODUCTS
} from "../constants";
import {GetLocalStorage} from "../../utils/helpers";
import {IProduct} from "../../types/types";

const initialState = {
  products: [],
  product: {},
  // paginate: {},
  loadingProducts: true,
  loadingProduct: true,
  popular_product: []
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {

    case LOADING_PRODUCTS: {
      return {
        ...state,
        loadingProducts: action.payload
      }
    }

    case SET_PRODUCTS: {
      const _products = GetLocalStorage('products', initialState)
      let _new_products = []
      if (_products.length > 0) {
        _new_products = action.payload.products.map(item => {
          const _card_product = _products.find(it => it.id === item.id)
          if (_card_product) {
            return _card_product
          } else {
            return item
          }
        })
      } else {
        _new_products = action.payload.products
      }
      return {
        ...state,
        products: _new_products,
      }
    }

    case RELOAD_PRODUCT_AFTER_ADD_CARD: {
      const _products = state.products
      const _popular_product = state.popular_product
      let _product = state.product
      _products.forEach(item => {
        if (item.id === action.payload.id) {
          item.count = 1
        }
      })
      _popular_product.forEach(item => {
        if (item.id === action.payload.id) {
          item.count = 1
        }
      })

      if(action.payload.id === _product.id){
        _product.count = 1
      }

      return {
        ...state,
        products: _products,
        popular_product: _popular_product,
        product: _product
      }
    }

    case RELOAD_PRODUCT_AFTER_REMOVE_CARD: {
      const _product = state.product
      const _products = state.products.map(item => {
        if (item.id === action.payload.id) {
          delete item['count']
        }
        return item
      })
      const _popular_product = state.popular_product.map(item => {
        if (item.id === action.payload.id) {
          delete item['count']

        }
        return item
      })

      if(action.payload.id === _product.id){
        delete _product['count']
      }
      // delete _product["count"]
      return {
        ...state,
        products: _products,
        popular_product: _popular_product,
        product: _product
      }
    }

    case SET_PRODUCT: {
      let _product = {}
      const _products = GetLocalStorage('products', initialState)
      // console.log(action.payload)
      if (_products.length > 0) {
        _products.map(item => {
          if (item.id === action.payload.id) {
            item.count = 1
            _product = item
          } else {
            _product = action.payload
          }
        })
      } else {
        _product = action.payload
      }
      return {
        ...state,
        product: _product,
        loadingProduct: false
      }
    }

    case SET_POPULAR_PRODUCTS: {
      const _products = GetLocalStorage('products', initialState)
      let _popular_product = []
      if (_products.length > 0) {
        _popular_product = action.payload.map(item => {
          const _card_product = _products.find(it => it.id === item.id)
          if (_card_product) {
            return _card_product
          } else {
            return item
          }
        })
      } else {
        _popular_product = action.payload
      }
      return {
        ...state,
        popular_product: _popular_product
      }
    }


    case RESET_PRODUCTS:
      return {
        ...state,
        product: {},
        products: [],
        loadingProducts: true,
        loadingProduct: true,
      }

    default:
      return state;
  }
}
export default productsReducer
