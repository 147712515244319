import {
  SET_PRODUCTS, RESET_PRODUCTS,
  SET_PRODUCT, LOADING_PRODUCTS,
  RELOAD_PRODUCT_AFTER_REMOVE_CARD, RELOAD_PRODUCT_AFTER_ADD_CARD, SET_POPULAR_PRODUCTS
} from "../constants";

export const setProductByModelAction = (data) => ({type: SET_PRODUCTS, payload: data})
export const loadingProducts = (boolean) => ({type: LOADING_PRODUCTS, payload: boolean})
export const setProduct = (data) => ({type: SET_PRODUCT, payload: data})
export const resetProducts = () => ({type: RESET_PRODUCTS})
export const reloadProductAfterAddCart = (data) => ({type: RELOAD_PRODUCT_AFTER_ADD_CARD, payload: data})
export const reloadProductAfterRemoveCart = (data) => ({type: RELOAD_PRODUCT_AFTER_REMOVE_CARD, payload: data})
export const setPopularProduct = (data) => ({type: SET_POPULAR_PRODUCTS, payload: data})



