import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {IMAGES} from "../../constants/images";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {changeFromInput} from "../../redux/actions/making_order";

const DeliveryProduct = () => {

  const dispatch = useDispatch()
  const making_order = useSelector((state: RootState) => state.making_order);
  const handleChangeInput = (field: any, value: any) => {
    const data = {
      field, value
    }
    dispatch(changeFromInput(data))
  }

  return (
    <Container fluid className="cart-area">
      <Container className="cart-area__container">
        <div className="cart-block">
          <div className="cart-block__title-block">
            <h2 className="cart-block__title">
              Доставка товара
            </h2>
          </div>

          <div className="form-order form-delivery-area">

            <h4 className="form-order__title">
              Как и где вы хотите получить заказ
            </h4>

            <div className="form-delivery">
              <label htmlFor="Pickup" className="form-check">
                <input
                  checked={"Самовывоз" === making_order.delivery}
                  onChange={(e) => handleChangeInput(e.target.getAttribute('aria-describedby'), 'Самовывоз')}
                  className="form-check-input"
                  aria-describedby="delivery"
                  type="radio" name="type" value="" id="Pickup"/>
                <div className="checkbox"/>
                Самовывоз
              </label>
              <label htmlFor="Delivery" className="form-check">

                <input
                  checked={"Доставка" === making_order.delivery}
                  onChange={(e) => handleChangeInput(e.target.getAttribute('aria-describedby'), 'Доставка')}
                  className="form-check-input"
                  aria-describedby="delivery"
                  type="radio" name="type" value="" id="Delivery"/>
                <div className="checkbox"/>
                Доставка
              </label>
            </div>

            <div className="form-delivery form-delivery__comment">
              <Row className="flex-grow-1">
                <Col lg={8} md={6} sm={12}>
                  <div className="mb-3">
                    <label htmlFor="comment" className="form-label">комментарии</label>
                    <textarea
                      value={making_order.delivery_comment}
                      onChange={(e) => handleChangeInput(e.target.getAttribute('aria-describedby'), e.target.value)}
                      className="form-control form-order__form-control" aria-describedby="delivery_comment"/>
                  </div>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <div className="mb-3 ">
                    <label htmlFor="" className="form-label form-label-hidden">&nbsp;</label>
                    <p className="fields-mandatory">
                      * Cроки отгрузки заказных позиций являются ориентировочными
                      {/*в связи с распространением в мире*/}
                      {/*коронавирусной инфекции.*/}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>

            {/*<p className="form-delivery-area__info">*/}
            {/*  После оформления заказа на указаный номер телефона придет sms-сообщение с номером заказа. Если вы*/}
            {/*  оставляете адрес электронной почты, то на указанный адрес автоматически сформируется и отправится состав*/}
            {/*  заказа и его описание.*/}
            {/*</p>*/}

          </div>

          <div className="delivery-organization-area">
            <img src={IMAGES.DeliveryOrg1} alt="" className="delivery-organization-logo"/>
            <img src={IMAGES.DeliveryOrg2} alt="" className="delivery-organization-logo"/>
            <img src={IMAGES.DeliveryOrg3} alt="" className="delivery-organization-logo"/>
            <img src={IMAGES.DeliveryOrg4} alt="" className="delivery-organization-logo"/>
            <img src={IMAGES.DeliveryOrg5} alt="" className="delivery-organization-logo"/>
          </div>

        </div>
      </Container>
    </Container>
  );
};

export default DeliveryProduct;
