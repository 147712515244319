import React from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Col, Row} from "react-bootstrap";
import {colorSkeleton} from "../../constants";

const ModelCardSkeleton = () => {
  return (
    <>
      {Array(8).fill(0).map((_, index) => {
        return (
          <Col lg={3} md={4} sm={6} xs={12} key={index}>
            <SkeletonTheme
              baseColor={colorSkeleton['baseColor']}
              highlightColor={colorSkeleton['highlightColor']}
            >
              <Skeleton count={1} height={400} style={{marginBottom: 30}}/>
            </SkeletonTheme>
          </Col>
        )
      })}
    </>
  );
};

export default ModelCardSkeleton;
