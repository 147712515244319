import React from 'react';
import {Helmet} from "react-helmet";
import PageTransition from "../components/PageTransition";
import {Col, Container} from "react-bootstrap";
import {IMAGES} from "../constants/images";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import FormInvite from "../components/forms/form-invite/FormInvite";
import {Link} from "react-router-dom";
import FormCommunicationManager from "../components/forms/form-communication-manager/FormCommunicationManager";
import Fancybox from "../hooks/Fancybox";
import {imageUrl} from "../constants";

const InvitePage = (props) => {
  return <PageTransition>
    <section className="delivery-page invite-page">
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{props.title}</title>
      </Helmet>
      <Container fluid className="delivery-page-banner">
        <img
          src={IMAGES.InviteBg}
          className="main-banner__image"
          alt="Как стать дилером"
        />

        <Container className="delivery-page-banner__container">
          <div className="main-banner__title-block delivery-page-banner__title-block">
            <h1 className="main-banner__title delivery-page-banner__title">
              как стать дилером
            </h1>
          </div>
        </Container>
      </Container>

      <Breadcrumbs/>

      <Container fluid className="delivery-page-payment">
        <Container>
          <div className="title-block title-line delivery-page-payment__title-block">
            <h2>стать дилером</h2>
          </div>
        </Container>
      </Container>

      <Container fluid className="invite-page-form-area" id="form-invite">
        <FormInvite/>
      </Container>

      <Container fliud className="invite-page-info">
        <Container>
          <div className="title-block delivery-page-payment__title-block">
            <h2>о производителе</h2>
          </div>
          <p className="invite-page-text">
            <span>STARMAXX</span> является торговой маркой компании <span>PETLAS</span> Tire Industry Co. Ассортимент
            шин Starmaxx
            представлен покрышками для различных категорий транспорта и специализированной техники —
            от легковых автомобилей до индустриальных машин.
          </p>
        </Container>
      </Container>

      <Container fluid className="invite-page-detail">
        <img
          className="invite-page-detail__image"
          src={IMAGES.InviteBg1}
          alt="подробнее о компании"/>
        <Container>
          <div className="invite-page-detail__list">
            <div className="company-product-list">
              <div className="company-product-item">
                <img src={IMAGES.TyreMenu1} alt="" className="company-product-item__image"/>
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car1} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для легковых машин
                </p>
              </div>
              <div className="company-product-item">
                <img src={IMAGES.TyreMenu2} alt="" className="company-product-item__image"/>
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car2} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для грузовиков
                </p>
              </div>
              <div className="company-product-item">
                <img src={IMAGES.TyreMenu3} alt="" className="company-product-item__image"/>
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car3} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для сельхозтехники
                </p>
              </div>
              <div className="company-product-item">
                <img src={IMAGES.TyreMenu4} alt="" className="company-product-item__image"/>
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car4} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для спецтехники
                </p>
              </div>
              <div className="company-product-item">
                <img src={IMAGES.TyreMenu5} alt="" className="company-product-item__image"/>
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car5} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для самолетов
                </p>
              </div>
            </div>
            <div className="invite-page-detail__button-area">
              <Link to="/about/about-company" className="button button--blue invite-page-detail__button-blue">
                <span>подробнее о компании</span>
              </Link>
            </div>
          </div>

        </Container>
      </Container>

      <Container fluid className="invite-page-about">

        <Container fluid className="invite-page-about__title">
          <div className="title-block title-line delivery-page-payment__title-block">
            <h2>о дистрибьюторе</h2>
          </div>
        </Container>
        <Container fluid className="invite-page-about__info">
          <Container>
            <p className="invite-page-text">
              <span>ООО «МТК Росберг Центр»</span> — это крупный и надежный поставщик шин, оригинальных и аналоговых
              запасных частей, а также сопутствующих услуг по легковому, грузовому шиномонтажу, ремонту легковых и
              грузовых автомобилей.
              Компания успешно функционирует более 12 лет.
              <br/><br/>
              Мы является официальным дилером более 20 крупнейших производителей шин, а также самостоятельно импортируем
              более 15 всемирно известных брендов по шинам.
            </p>
            <div className="invite-steps">
              <div className="invite-steps__col">
                <div className="invite-step" data-count="1">
                  Опыт работы на рынке поставок
                  шин и запасных частей более 12 лет
                </div>
                <div className="invite-step" data-count="2">
                  Качество, оперативность и широкий ассортимент поставляемого товара
                </div>
                <div className="invite-step" data-count="3">
                  Профессиональный коллектив
                </div>
              </div>
              <div className="invite-steps__col ">


                <div className="invite-sert">
                  <Fancybox options={{infinite: false}}>
                    <div
                      data-fancybox="ser"
                      data-src={IMAGES.SertBig7}>
                      <img src={IMAGES.Sert7}
                           alt="сертификат"
                           width="275"
                           height="383"
                      />
                    </div>
                  </Fancybox>

                </div>
              </div>
              <div className="invite-steps__col">
                <div className="invite-step" data-count="4">
                  Максимально сжатые сроки
                  обработки и выполнения заказов

                </div>
                <div className="invite-step" data-count="5">
                  Гарантия на все реализуемые
                  товары
                </div>
                <div className="invite-step" data-count="6">
                  Собственный склад для хранения
                  продукции
                </div>
              </div>
            </div>
            <div className="invite-page-about__button-area">
              <a
                className="button button--gray invite-page-about__button-gray"
                href="https://mtkrosberg.ru/" target="_blank">
                <span>на сайт «МТК Росберг Центр»</span>
              </a>
              <a
                className="button button--red invite-page-about__button-red"
                href="#form-invite">
                <span>Стать дилером</span>
              </a>
            </div>
          </Container>
        </Container>
      </Container>

      <Container fluid className="invite-page-geo">

        {/*<Container fluid className="invite-page-about__title">*/}
        {/*  <div className="title-block delivery-page-payment__title-block">*/}
        {/*    <h2>география компании</h2>*/}
        {/*  </div>*/}
        {/*</Container>*/}

        {/*<Fancybox options={{infinite: false}}>*/}
        {/*  <div*/}
        {/*    className="geo-fancy"*/}
        {/*    data-fancybox="gallery"*/}
        {/*    data-src={IMAGES.Geo}>*/}
        {/*    <img*/}
        {/*      className="invite-page-geo__map"*/}
        {/*      src={IMAGES.Geo}*/}
        {/*      alt="Карта" width="100%" height="550"/>*/}
        {/*  </div>*/}
        {/*</Fancybox>*/}

        <Container className="geo-work">
          <p className="geo-work__text">
            Мы работаем во всех странах <span>Таможенного Союза!</span>
          </p>
          <div className="geo-work__list">
            <img className="geo-work__image" src={IMAGES.Geo1} alt="россия" width="215" height="148"/>
            <img className="geo-work__image" src={IMAGES.Geo2} alt="казахстан" width="215" height="148"/>
            <img className="geo-work__image" src={IMAGES.Geo3} alt="белоруссия" width="215" height="148"/>
            <img className="geo-work__image" src={IMAGES.Geo4} alt="армения" width="215" height="148"/>
            <img className="geo-work__image" src={IMAGES.Geo5} alt="киргизия" width="215" height="148"/>
          </div>
        </Container>

      </Container>

      <FormCommunicationManager/>
    </section>
  </PageTransition>;
};

export default InvitePage;
