import {
  TOGGLE_MODAL_THANKS, TOGGLE_MODAL_CALL,
  TOGGLE_MOBILE_MENU, TOGGLE_MODAL_DELETE, TOGGLE_MODAL_FILTER_MOBILE, TOGGLE_MODAL_CALL_MOBILE
} from "../constants";

const initialState = {
  modalThanks: false,
  modalCall: false,
  modalCallMobile: false,
  mobile_menu: false,
  modal_delete: false,
  modal_filter_mobile: false,
}

const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL_THANKS: {
      return {
        ...state,
        modalThanks: action.payload
      }
    }

    case TOGGLE_MODAL_CALL: {
      return {
        ...state,
        modalCall: action.payload
      }
    }
    case TOGGLE_MODAL_CALL_MOBILE: {
      return {
        ...state,
        modalCallMobile: action.payload
      }
    }
    case TOGGLE_MOBILE_MENU: {
      return {
        ...state,
        mobile_menu: action.payload
      }
    }
    case TOGGLE_MODAL_DELETE: {
      return {
        ...state,
        modal_delete: action.payload
      }
    }
    case TOGGLE_MODAL_FILTER_MOBILE: {
      return {
        ...state,
        modal_filter_mobile: action.payload
      }
    }
    default:
      return state;
  }
}
export default modalsReducer;
