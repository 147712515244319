import {SET_FILTERS, LOADING_FILTER, CREATE_URL, SET_PAGINATION, RESET_FILTERS} from "../constants";

export const loadingFilter = (data:boolean) => ({type: LOADING_FILTER, payload: data})
export const setFilter = (data) => ({type: SET_FILTERS, payload: data})
export const createUrl = (data) => ({type: CREATE_URL, payload: data})
export const createUrlRange = (data) => ({type: 'CREATE_URL_RANGE', payload: data})
export const setPagination = (data) => ({type: 'SET_PAGINATION', payload: data})
export const changeFilterType = (data) => ({type: 'CHANGE_FILTER_TYPE', payload: data})
export const resetFilters = () => ({type: 'RESET_FILTERS'})
export const filterCount = (data) => ({type: 'FILTER_COUNT', payload: data})
