import React, {useState, useEffect} from 'react';
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {IMAGES} from "../../constants/images";
import api from "../../api";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {colorSkeleton} from "../../constants";

const NavigationModel = () => {

  const [sections, setSections] = useState([])
  const [loading, setLoading] = useState(true)

  const init = async () => {
    await api.getFilterLinkSections().then((response) => {
      setSections(response.sections)
      setLoading(false)
    }).catch(e => {
      console.log(e)
    })
  }

  useEffect(() => {
    init()
  }, []);

  return (
    <Container fluid className="navigation-model-area bg-gray">
      <Container>
        <h2>Выбор шин по типу транспорта</h2>

        {loading
          ?
          <SkeletonTheme
            baseColor={colorSkeleton["baseColor"]}
            highlightColor={colorSkeleton["highlightColor"]}>
            <Skeleton count={1} height={77}/>
          </SkeletonTheme>
          :
          <div className="navigation-model">

            <Link
              to={`catalog?sections=14`}
              className="navigation-model__block">
              <img
                className="navigation-model__image"
                src={IMAGES.Model2}
                alt="Шины для грузовиков"
              />
              <span className="navigation-model__name">
                шины для сельхозтехники
              </span>
            </Link>

            <Link
              to={`catalog?sections=27`}
              className="navigation-model__block">
              <img
                className="navigation-model__image"
                src={IMAGES.Model3}
                alt="Шины для грузовиков"
              />
              <span className="navigation-model__name">
                      шины для спецтехники
                    </span>
            </Link>

            <Link
              to={`catalog?sections=18`}
              className="navigation-model__block">
              <img
                className="navigation-model__image"
                src={IMAGES.Model1}
                alt="Шины для грузовиков"
              />
              <span className="navigation-model__name">
                      шины для грузовиков
                    </span>
            </Link>

          </div>
        }

      </Container>
    </Container>

  );
};

export default NavigationModel;
