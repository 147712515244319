import {MAKING_ORDER, GET_MAKING_ORDER,
  SELECT_PAYMENT_METHOD, CHANGE_FORM_INPUT,
  RESET_MAKING_ORDER, SET_ERRORS, RESET_ERRORS,
  ORDER_ACCESS} from "../constants";
import {GetLocalStorage} from "../../utils/helpers";

const initialState = {
  making: false,
  payment_method: {
    id: 1,
    name: 'Онлайн оплата'
  },
  fio: '',
  phone: '',
  email: '',
  organization: '',
  legal_address: '',
  comment: '',
  inn: '',
  agreement: false,
  delivery: 'Самовывоз',//Самовывоз, Доставка
  delivery_comment: '',
  errors: {
    fio: '',
    phone: '',
    delivery: '',
    payment_method_name: '',
    agreement: ''
  },
  order_access: false
}

const makingOrderReducer = (state = initialState, action) => {

  switch (action.type) {

    case GET_MAKING_ORDER: {
      const _making_order_storage = GetLocalStorage('making_order', initialState)
      return state = _making_order_storage
    }

    case MAKING_ORDER: {
      const _making = !state.making
      const _making_order_storage = GetLocalStorage('making_order', initialState)
      // console.log(_making_order_storage)
      _making_order_storage.making = _making
      localStorage.setItem('making_order', JSON.stringify(_making_order_storage))
      return {
        ...state,
        making: _making
      }
    }

    case SELECT_PAYMENT_METHOD: {
      const _making_order_storage = GetLocalStorage('making_order', initialState)
      _making_order_storage.payment_method.id = action.payload.id
      _making_order_storage.payment_method.name = action.payload.name
      localStorage.setItem('making_order', JSON.stringify(_making_order_storage))
      return {
        ...state,
        payment_method: {
          ...state.payment_method,
          id: action.payload.id,
          name: action.payload.name
        }
      }
    }

    case CHANGE_FORM_INPUT: {
      const _making_order_storage = GetLocalStorage('making_order', initialState)
      _making_order_storage[action.payload.field] = action.payload.value
      localStorage.setItem('making_order', JSON.stringify(_making_order_storage))
      return {
        ...state,
        [action.payload.field]: action.payload.value,
        // errors: {
        //   ...state.errors,
        //   [action.payload.field]: ''
        // }
      }
    }

    case SET_ERRORS:{
      return {
        ...state,
        errors:{
          ...state.errors,
          [action.payload.field]: action.payload.value
        }
      }
    }
    case RESET_ERRORS:{
      return {
        ...state,
        errors: initialState.errors
      }
    }

    case ORDER_ACCESS: {
      return {
        ...state,
        order_access: action.payload
      }
    }

    case RESET_MAKING_ORDER:{
      localStorage.removeItem('making_order')
      return initialState
    }

    default: {
      return state
    }

  }
}
export default makingOrderReducer;
