import React from "react";
import {AnimatePresence, motion} from "framer-motion";

const PageTransition = ({children}) => {

  const AnimationSettings = {
    transition: {duration: 0.2},
    initial: {opacity: 0.8, y: 0, scale: 1.01},
    animate: {opacity: 1, y: 0, scale: 1},
    exit: {opacity: 0.8, y: 0, scale: 1.01}
  };

  return (
    <AnimatePresence>
      <motion.div className="route-container" key="1" {...AnimationSettings}>
        {children}
      </motion.div>
    </AnimatePresence>
  );

};

export default PageTransition;
