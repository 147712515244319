export const SET_MODELS = "SET_MODELS"
export const SET_MODEL = "SET_MODEL"
export const RESET_MODELS = "RESET_MODELS"
export const SET_PRODUCTS = "SET_PRODUCTS"
//загрузка для продуктов
export const LOADING_PRODUCTS = "LOADING_PRODUCTS"

export const RESET_PRODUCTS = "RESET_PRODUCTS"
//Сброс корзины
export const RESET_CART = "RESET_CART"
//Добавление товара в корзину и localStorage
export const SET_PRODUCT_CART = "SET_PRODUCT_CART"
//Получаем данные с localStorage для корзины
export const GET_PRODUCT_CART = "GET_PRODUCT_CART"
//Сброс модвлки "Товар добавлен"
export const RESET_MODAL_CART = "RESET_MODAL_CART"
//Модалка при добавление товара в корзины
export const OPEN_MODAL_CART = "OPEN_MODAL_CART"
//Уменьшаем количество товара на одну штуку
export const REMOVE_PRODUCT_UNIT_CART = "REMOVE_PRODUCT_UNIT_CART"
//Выделяем все товары
export const SELECT_ALL_PRODUCT = "SELECT_ALL_PRODUCT"
//Удаляем товар с корзины через кнопку УДАЛИТЬ ВЫБРАННЫЕ
export const REMOVE_ALL_PRODUCT_CART = "REMOVE_ALL_PRODUCT_CART"
//Удаляем товар с корзины через кнопку УДАЛИТЬ
export const DELETE_PRODUCT = "DELETE_PRODUCT"
//открывает выпадаюшее окно корзины
export const TOGGLE_DROP_WINDOW_CART = "TOGGLE_DROP_WINDOW_CART"
//select продукта в корзине
export const SELECT_PRODUCT_UNIT_CART = "SELECT_PRODUCT_UNIT_CART"
//При выходе со страници Корзина сбрасывает селект продукта
export const UNSELECT_PRODUCT_CART = "UNSELECT_PRODUCT_CART"
//Кнопка перейти к оформлениею
export const MAKING_ORDER = "MAKING_ORDER"
//получает информацию про доставку из localStorage
export const GET_MAKING_ORDER = "GET_MAKING_ORDER"
//Выбор метода оплаты
export const SELECT_PAYMENT_METHOD = "SELECT_PAYMENT_METHOD"
//Ввод данных для оформления доставки
export const CHANGE_FORM_INPUT = "CHANGE_FORM_INPUT"
//Сброс оформления заказа
export const RESET_MAKING_ORDER = "RESET_MAKING_ORDER"
//Ошибки при оформлении заказа
export const SET_ERRORS = "SET_ERRORS"
//Сброс ошибок формы
export const RESET_ERRORS = "RESET_ERRORS"
//модалка успешного оформления заказа
export const ORDER_ACCESS = "ORDER_ACCESS"
//Закидываем фильтры в store
export const SET_FILTERS = "SET_FILTERS"
//загрузка фильтров
export const LOADING_FILTER = "LOADING_FILTER"
//закидываем продукт который просматриваем в store
export const SET_PRODUCT = "SET_PRODUCT"
//создаем url из фильтров
export const CREATE_URL = "CREATE_URL"
//получаем пагинацию
export const SET_PAGINATION = "SET_PAGINATION"
//модалка "спасибо за обращение"
export const TOGGLE_MODAL_THANKS = "TOGGLE_MODAL_THANKS"
//модалка "обратный звонок"
export const TOGGLE_MODAL_CALL = "TOGGLE_MODAL_CALL"

export const TOGGLE_MODAL_CALL_MOBILE = "TOGGLE_MODAL_CALL_MOBILE"

export const CHANGE_FILTER_TYPE = "CHANGE_FILTER_TYPE"

export const RESET_FILTERS = "RESET_FILTERS"

export const TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU"

export const TOGGLE_MODAL_DELETE = "TOGGLE_MODAL_DELETE"

export const SET_DELETE_PRODUCT = "SET_DELETE_PRODUCT"
//обновление товаров при добавление их в корзину
export const RELOAD_PRODUCT_AFTER_ADD_CARD = "RELOAD_PRODUCT_AFTER_ADD_CARD"
//обновление товаров при добавление их в корзину
export const RELOAD_PRODUCT_AFTER_REMOVE_CARD = "RELOAD_PRODUCT_AFTER_REMOVE_CARD"
//получаем популярные товары
export const SET_POPULAR_PRODUCTS = "SET_POPULAR_PRODUCTS"

export const TOGGLE_MODAL_FILTER_MOBILE = "TOGGLE_MODAL_FILTER_MOBILE"

